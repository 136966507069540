import { h } from 'preact'

import { store, setCompanySelection, setContactSelection } from '../redux/store.js'

import { route } from 'preact-router'

import { Grid, Card, Icon, Table, List, Button, Flag } from 'semantic-ui-react'

import { Text } from 'preact-i18n'

const contactTableCells = {
    greetings: {
        data: ['Salutation', 'Firstname', 'Lastname', 'Mybuschjaegerusername'],
    },
    contactings: {
        icons: {
            Phone: <Icon name="phone" />,
            Mobile: <Icon name="mobile alternate" />,
            Fax: <Icon name="fax" />,
            Mail: <Icon name="mail" />,
        },
        as: {
            Mail: {
                type: 'a',
                props: {
                    href: {
                        interpolate: true,
                        value: 'mailto:',
                    },
                },
            },
        },
        data: ['Phone', 'Mobile', 'Fax', 'Mail'],
    },
}

const companyTableCells = {
    identifier: {
        rowspan: 2,
        cellWidth: 3,
        data: ['Edvnr', 'Mccode'],
    },
    greetings: {
        rowspan: 2,
        cellWidth: 4,
        data: ['Salutation', 'Name', 'Nameextend', 'Country', 'Zipcode', 'City', 'Street'],
        showValueAsIcon: ['Country'],
        as: {
            Country: {
                type: Flag,
                props: {
                    class: {
                        interpolate: true,
                        toLower: true,
                        value: '',
                    },
                },
            },
        },
    },
    marker: {
        colspan: 2,
        cellWidth: 2,
        data: {
            Emaster: true,
            Preferredpartner: true,
        },
    },
    contactings: {
        cellWidth: 5,
        rowspan: 3,
        icons: {
            Phone: <Icon name="phone" />,
            Mobile: <Icon name="mobile alternate" />,
            Fax: <Icon name="fax" />,
            Mail: <Icon name="mail" />,
        },
        as: {
            Mail: {
                type: 'a',
                props: {
                    href: {
                        interpolate: true,
                        value: 'mailto:',
                    },
                },
            },
            Homepage: {
                type: 'a',
                props: {
                    href: {
                        interpolate: true,
                        value: 'http://',
                    },
                    target: {
                        interpolate: false,
                        value: '_blank',
                    },
                },
            },
        },
        data: ['Phone', 'Mobile', 'Fax', 'Mail', 'Homepage'],
    },
}

const getItemProps = (cellData, cell, forObj) => {
    const ret = {}
    let dataVal = forObj['get' + cell]()
    if (dataVal && cellData.as && cellData.as[cell]) {
        Object.entries(cellData.as[cell].props).map((prop, idx) => {
            if (prop[1].toLower) {
                dataVal = dataVal.toLowerCase()
            }
            if (prop[1].interpolate) {
                switch (cell) {
                    case 'Homepage':
                        // Cut any protocols if exists
                        dataVal = dataVal.replace(/(^\w+:|^)\/\//, '')
                        break
                }
                ret[prop[0]] = `${prop[1].value}${dataVal}`
            } else {
                ret[prop[0]] = prop[1].value
            }
        })
    }

    return ret
}

const viewDetails = (ev, ele) => {
    let obj = null
    window.scrollTo(window.scrollX, 0)

    switch (ele.datatype) {
        case 'company':
            obj = ele.company
            store.dispatch(setCompanySelection(obj))
            break
        case 'contact':
            obj = ele.contact
            store.dispatch(setContactSelection(ele.contact))
            break
    }

    route('/backend/v2/mitgliedersuche/' + ele.datatype + '/' + obj.getId())
}

const contactTableHeader = () => {
    let headerData = Object.entries(contactTableCells)

    const headerRow = (
        <Table.Row>
            {headerData.map((ele, idx) => {
                const key = `attributes.contact.${ele[0]}`.toLowerCase()

                return (
                    <Table.HeaderCell
                        colSpan={headerData[idx][1].colspan}
                        rowSpan={headerData[idx][1].rowspan}
                        width={headerData[idx][1].cellWidth}
                    >
                        <Text id={key}>{key}</Text>
                    </Table.HeaderCell>
                )
            })}
        </Table.Row>
    )

    const header = [headerRow]

    return <Table.Header>{header}</Table.Header>
}
const companyTableHeader = () => {
    let subHeaderCells = []
    let headerData = Object.entries(companyTableCells)

    const headerRow = (
        <Table.Row>
            {headerData.map((ele, idx) => {
                const key = `attributes.company.${ele[0]}`.toLowerCase()
                if (!Array.isArray(headerData[idx][1].data) && typeof headerData[idx][1].data === 'object') {
                    Object.keys(headerData[idx][1].data).map((ele) => {
                        const subkey = `attributes.company.${ele}`.toLowerCase()
                        subHeaderCells[subHeaderCells.length] = (
                            <Table.HeaderCell>
                                <Text id={subkey}>{subkey}</Text>
                            </Table.HeaderCell>
                        )
                    })
                }

                return (
                    <Table.HeaderCell
                        colSpan={headerData[idx][1].colspan}
                        rowSpan={headerData[idx][1].rowspan}
                        width={headerData[idx][1].cellWidth}
                    >
                        <Text id={key}>{key}</Text>
                    </Table.HeaderCell>
                )
            })}
        </Table.Row>
    )

    const subHeaderRow = <Table.Row>{subHeaderCells}</Table.Row>

    const header = [headerRow, subHeaderRow]

    return <Table.Header>{header}</Table.Header>
}

const company = (ele, layout) => {
    const data = ele.getCompany()

    if (!data) {
        return
    }

    const gridLayout = (
        <Grid.Column>
            <Card company={data} datatype="company" onClick={viewDetails}>
                <Card.Content>
                    <Card.Header>{data.getName()}</Card.Header>
                </Card.Content>
                <Card.Content extra>
                    <Icon name="user" />
                    {data.getContactList().length}
                </Card.Content>
            </Card>
        </Grid.Column>
    )

    let tableCells = []
    for (let cellData in companyTableCells) {
        if (Array.isArray(companyTableCells[cellData].data)) {
            tableCells[tableCells.length] = (
                <Table.Cell verticalAlign="top" width={companyTableCells[cellData].cellWidth}>
                    <List divided>
                        {Array.isArray(companyTableCells[cellData].data) &&
                            companyTableCells[cellData].data.map((val, idx) => {
                                const dataVal = data['get' + val]()
                                return (
                                    <List.Item>
                                        <List.Content
                                            floated="right"
                                            as={
                                                companyTableCells[cellData].as && companyTableCells[cellData].as[val]
                                                    ? companyTableCells[cellData].as[val].type
                                                    : 'div'
                                            }
                                            {...getItemProps(companyTableCells[cellData], val, data)}
                                        >
                                            {companyTableCells[cellData].showValueAsIcon &&
                                                companyTableCells[cellData].showValueAsIcon.includes(val)
                                                ? ''
                                                : dataVal || ''}
                                        </List.Content>
                                        {companyTableCells[cellData].icons && companyTableCells[cellData].icons[val] ? (
                                            <List.Icon>{companyTableCells[cellData].icons[val]}</List.Icon>
                                        ) : (
                                            <List.Content>
                                                <Text id={`attributes.company.${val.toLowerCase()}`}>{val}</Text>
                                            </List.Content>
                                        )}
                                    </List.Item>
                                )
                            })}
                        {tableCells.length === 0 && (
                            <List.Item>
                                <Button
                                    size="mini"
                                    compact
                                    fluid
                                    color="teal"
                                    content={<Text id="show_detail_view">goto Details</Text>}
                                    onClick={(ev) => viewDetails(ev, { company: data, datatype: 'company' })}
                                />
                            </List.Item>
                        )}
                    </List>
                </Table.Cell>
            )
        }

        if (!Array.isArray(companyTableCells[cellData].data) && typeof companyTableCells[cellData].data === 'object') {
            Object.entries(companyTableCells[cellData].data).map((ele, idx) => {
                const dataVal = data['get' + ele[0]]()
                if (typeof dataVal === 'boolean') {
                    tableCells[tableCells.length] = (
                        <Table.Cell
                            width={
                                companyTableCells[cellData].cellWidth /
                                Object.keys(companyTableCells[cellData].data).length
                            }
                        >
                            <Icon size="large" name={dataVal ? 'check' : 'close'} color={dataVal ? 'green' : 'red'} />
                        </Table.Cell>
                    )
                } else {
                    tableCells[tableCells.length] = (
                        <Table.Cell width={companyTableCells[cellData].cellWidth}>{data['get' + ele[0]]()}</Table.Cell>
                    )
                }
            })
        }
    }

    const tableLayout = <Table.Row>{tableCells}</Table.Row>

    const component = layout === 'grid' ? gridLayout : tableLayout

    return component
}

const contact = (ele, layout) => {
    const data = ele.getContact()
    if (!data) {
        return
    }

    const gridLayout = (
        <Grid.Column>
            <Card contact={data} datatype="contact" onClick={viewDetails} header={data.getFirstname()}></Card>
        </Grid.Column>
    )

    let tableCells = []
    for (let cellData in contactTableCells) {
        if (Array.isArray(contactTableCells[cellData].data)) {
            tableCells[tableCells.length] = (
                <Table.Cell verticalAlign="top" width={contactTableCells[cellData].cellWidth}>
                    <List divided>
                        {Array.isArray(contactTableCells[cellData].data) &&
                            contactTableCells[cellData].data.map((val, idx) => {
                                const dataVal = data['get' + val]()
                                return (
                                    <List.Item>
                                        <List.Content
                                            floated="right"
                                            as={
                                                contactTableCells[cellData].as && contactTableCells[cellData].as[val]
                                                    ? contactTableCells[cellData].as[val].type
                                                    : 'div'
                                            }
                                            {...getItemProps(contactTableCells[cellData], val, data)}
                                        >
                                            {contactTableCells[cellData].showValueAsIcon &&
                                                contactTableCells[cellData].showValueAsIcon.includes(val)
                                                ? ''
                                                : dataVal || ''}
                                        </List.Content>
                                        {contactTableCells[cellData].icons && contactTableCells[cellData].icons[val] ? (
                                            <List.Icon>{contactTableCells[cellData].icons[val]}</List.Icon>
                                        ) : (
                                            <List.Content>
                                                <Text id={`attributes.contact.${val.toLowerCase()}`}>{val}</Text>
                                            </List.Content>
                                        )}
                                    </List.Item>
                                )
                            })}
                        {tableCells.length === 0 && (
                            <List.Item>
                                <Button
                                    size="mini"
                                    compact
                                    fluid
                                    color="teal"
                                    content={<Text id="show_detail_view">goto Details</Text>}
                                    onClick={(ev) => viewDetails(ev, { contact: data, datatype: 'contact' })}
                                />
                            </List.Item>
                        )}
                    </List>
                </Table.Cell>
            )
        }
    }

    const tableLayout = <Table.Row>{tableCells}</Table.Row>

    const component = layout === 'grid' ? gridLayout : tableLayout

    return component
}

export { company, contact, companyTableHeader, contactTableHeader }
