import { configureStore, createSlice, getDefaultMiddleware, createAsyncThunk } from '@reduxjs/toolkit'

import { fetchAccessToken } from '@bjep-extmod/ispf-auth'

import grpcclient from '../proto/compiled/grpc/grpc_grpc_web_pb'

const filterResultSet = {
    activePage: 1,
    elementsPerPage: 10,
    resultSearchAttribute: 'name',
}

const apiClient = new grpcclient.CrmClient(process.env.PREACT_APP_API_URI)

const auth = createAsyncThunk('accesstoken/retrieve', async (payload, thunk) => {
    return await fetchAccessToken().then((res) => res.accessToken)
})

export const fetchData = createAsyncThunk('api/fetch', async (payload, thunk) => {
    if (!store.getState().accesstoken) {
        await thunk.dispatch(auth())
    }

    const fetchData = new Promise((resolve, reject) => {
        let data = {}

        const meta = {
            'x-auth-ispf': store.getState().accesstoken,
        }

        switch (payload.type) {
            case 'listresolver':
                apiClient.resolveListValues(payload.resolve, meta, (err, response) => {
                    if (err) {
                        if (err.code == 16) {
                            // Unauthorized
                            window.location = '/'
                            reject('UNAUTHORIZED')
                        }
                    }
                    data.value = response.toJavaScript().map((ele, idx) => {
                        return {
                            key: ele.name,
                            text: `${ele.name} (${ele.counter})`,
                            value: ele.name,
                            obj: ele,
                        }
                    })

                    data.entity = payload.resolve.getEntitytype()
                    data.key = payload.resolve.getResolvekey()

                    resolve(data)
                })
                break
            case 'company':
                let req = new proto.Company()
                req.setId(payload.id)
                apiClient.getCompanyById(req, meta, (err, response) => {
                    if (err) {
                        if (err.code == 16) {
                            // Unauthorized
                            window.location = '/'
                            reject('UNAUTHORIZED')
                        }
                    }
                    data.selectedCompany = response
                    resolve(data)
                })
                break
            case 'contact':
                req = new proto.Contact()
                req.setId(payload.id)
                apiClient.getContactById(req, meta, (err, response) => {
                    if (err) {
                        if (err.code == 16) {
                            // Unauthorized
                            window.location = '/'
                            reject('UNAUTHORIZED')
                        }
                    }
                    data.selectedContact = response
                    resolve(data)
                })
                break
            case 'company_contacts':
                apiClient.getContactsForCompany(store.getState().company.selectedCompany, meta, (err, response) => {
                    if (err) {
                        if (err.code == 16) {
                            // Unauthorized
                            window.location = '/'
                            reject('UNAUTHORIZED')
                        }
                    }
                    resolve({ selectedCompany: response })
                })
                break
            default:
                reject('action unknown')
        }
    })

    return await fetchData
})

const listResolver = createSlice({
    name: 'listresolve',
    initialState: {
        loadingList: false,
        resolvedList: {},
    },
    extraReducers: {
        [fetchData.pending]: (state, action) => {
            state.loadingList = true
        },
        [fetchData.rejected]: (state, action) => {
            console.dir(action.error)
        },
        [fetchData.fulfilled]: (state, action) => {
            state.loadingList = false
            state.resolvedList[action.payload.entity] = {
                [action.payload.key]: action.payload.value,
            }
        },
    },
})

const companySlice = createSlice({
    name: 'company',
    initialState: {
        selectedCompany: null,
    },
    reducers: {
        setCompanySelection: (state, action) => {
            state.selectedCompany = action.payload
        },
    },
    extraReducers: {
        [fetchData.fulfilled]: (state, action) => {
            state.selectedCompany = action.payload.selectedCompany
        },
    },
})

const xAuthIspf = createSlice({
    name: 'x-auth-ispf',
    initialState: null,
    reducers: {
        setToken: (state, action) => action.paylaod,
    },
    extraReducers: {
        [auth.fulfilled]: (state, action) => action.payload,
    },
})

const contactSlice = createSlice({
    name: 'contact',
    initialState: {
        selectedContact: null,
    },
    reducers: {
        setContactSelection: (state, action) => {
            state.selectedContact = action.payload
        },
    },
    extraReducers: {
        [fetchData.fulfilled]: (state, action) => {
            state.selectedContact = action.payload.selectedContact
        },
    },
})

const visibilities = createSlice({
    name: 'visibility',
    initialState: 'search',
    reducers: {
        setVisibility: (state, action) => action.payload,
    },
})

const layout = createSlice({
    name: 'layout',
    initialState: 'table',
    reducers: {
        setLayout: (state, action) => action.payload,
    },
})

const filterResult = createSlice({
    name: 'filterResultSet',
    initialState: filterResultSet,
    reducers: {
        setActiveResultPage: (state, action) => {
            state.activePage = action.payload
        },
        setElementsPerPage: (state, action) => {
            state.elementsPerPage = action.payload
        },
        setResultSearchAttribute: (state, action) => {
            state.resultSearchAttribute = action.payload
        },
    },
})

export const { setToken } = xAuthIspf.actions
export const { setCompanySelection } = companySlice.actions
export const { setContactSelection } = contactSlice.actions
export const { setVisibility } = visibilities.actions
export const { setLayout } = layout.actions
export const { setActiveResultPage, setElementsPerPage, setResultSearchAttribute } = filterResult.actions

export const store = configureStore({
    reducer: {
        accesstoken: xAuthIspf.reducer,
        listresolver: listResolver.reducer,
        company: companySlice.reducer,
        contact: contactSlice.reducer,
        visibility: visibilities.reducer,
        layout: layout.reducer,
        filterResult: filterResult.reducer,
    },
    middleware: getDefaultMiddleware({
        immutableCheck: {
            // Ignore to store protobuf
            ignoredPaths: ['company.selectedCompany', 'contact.selectedContact'],
        },
        serializableCheck: {
            // Ignore to store protobuf
            ignoredActions: ['company/setCompanySelection', 'contact/setContactSelection', 'api/fetch'],
            ignoredActionPaths: ['payload.selectedCompany', 'payload.selectedContact', 'meta.arg'],
            ignoredPaths: ['company.selectedCompany', 'contact.selectedContact'],
        },
    }),
    devTools: process.env.REDUX_DEV_TOOL !== undefined && process.env.REDUX_DEV_TOOL == 'true',
})

store.dispatch(auth())
