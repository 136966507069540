// source: model/crm.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var model_order_pb = require('../model/order_pb.js');
goog.object.extend(proto, model_order_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.model.Adm', null, global);
goog.exportSymbol('proto.model.Company', null, global);
goog.exportSymbol('proto.model.Contact', null, global);
goog.exportSymbol('proto.model.SalesDistrictResponsibilities', null, global);
goog.exportSymbol('proto.model.SalesDistrictResponsibility', null, global);
goog.exportSymbol('proto.model.StructureAssignment', null, global);
goog.exportSymbol('proto.model.StructureAssignment.AssignmentType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.Company = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.Company.repeatedFields_, null);
};
goog.inherits(proto.model.Company, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.Company.displayName = 'proto.model.Company';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.Contact = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.Contact.repeatedFields_, null);
};
goog.inherits(proto.model.Contact, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.Contact.displayName = 'proto.model.Contact';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.Adm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.Adm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.Adm.displayName = 'proto.model.Adm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.StructureAssignment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.StructureAssignment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.StructureAssignment.displayName = 'proto.model.StructureAssignment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.SalesDistrictResponsibilities = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.SalesDistrictResponsibilities.repeatedFields_, null);
};
goog.inherits(proto.model.SalesDistrictResponsibilities, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.SalesDistrictResponsibilities.displayName = 'proto.model.SalesDistrictResponsibilities';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.SalesDistrictResponsibility = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.SalesDistrictResponsibility.repeatedFields_, null);
};
goog.inherits(proto.model.SalesDistrictResponsibility, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.SalesDistrictResponsibility.displayName = 'proto.model.SalesDistrictResponsibility';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.Company.repeatedFields_ = [33,11,36];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.Company.prototype.toObject = function(opt_includeInstance) {
  return proto.model.Company.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.Company} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Company.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    edvnr: jspb.Message.getFieldWithDefault(msg, 2, ""),
    universalid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mccode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    salutation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    nameextend: jspb.Message.getFieldWithDefault(msg, 7, ""),
    salesdistrict: jspb.Message.getFieldWithDefault(msg, 8, ""),
    targetgroup: jspb.Message.getFieldWithDefault(msg, 9, ""),
    subtargetgroup: jspb.Message.getFieldWithDefault(msg, 10, ""),
    authorityList: (f = jspb.Message.getRepeatedField(msg, 33)) == null ? undefined : f,
    phone: jspb.Message.getFieldWithDefault(msg, 23, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 24, ""),
    fax: jspb.Message.getFieldWithDefault(msg, 25, ""),
    mail: jspb.Message.getFieldWithDefault(msg, 26, ""),
    homepage: jspb.Message.getFieldWithDefault(msg, 27, ""),
    country: jspb.Message.getFieldWithDefault(msg, 28, ""),
    city: jspb.Message.getFieldWithDefault(msg, 29, ""),
    street: jspb.Message.getFieldWithDefault(msg, 30, ""),
    zipcode: jspb.Message.getFieldWithDefault(msg, 31, ""),
    district: jspb.Message.getFieldWithDefault(msg, 32, ""),
    contactList: jspb.Message.toObjectList(msg.getContactList(),
    proto.model.Contact.toObject, includeInstance),
    segmentation: jspb.Message.getFieldWithDefault(msg, 14, ""),
    segmentation1: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    segmentation2: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    preferredpartner: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    emaster: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    lastvisit: (f = msg.getLastvisit()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    taxid: jspb.Message.getFieldWithDefault(msg, 20, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    model_order_pb.Order.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.Company}
 */
proto.model.Company.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.Company;
  return proto.model.Company.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.Company} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.Company}
 */
proto.model.Company.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdvnr(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniversalid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMccode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalutation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNameextend(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalesdistrict(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetgroup(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtargetgroup(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.addAuthority(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setFax(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setMail(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setHomepage(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipcode(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistrict(value);
      break;
    case 11:
      var value = new proto.model.Contact;
      reader.readMessage(value,proto.model.Contact.deserializeBinaryFromReader);
      msg.addContact(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSegmentation(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSegmentation1(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSegmentation2(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPreferredpartner(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmaster(value);
      break;
    case 19:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastvisit(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxid(value);
      break;
    case 34:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 35:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 36:
      var value = new model_order_pb.Order;
      reader.readMessage(value,model_order_pb.Order.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.Company.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.Company.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.Company} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Company.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEdvnr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUniversalid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMccode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSalutation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNameextend();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSalesdistrict();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTargetgroup();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSubtargetgroup();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAuthorityList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      33,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getFax();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getMail();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getHomepage();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getZipcode();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getDistrict();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getContactList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.model.Contact.serializeBinaryToWriter
    );
  }
  f = message.getSegmentation();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getSegmentation1();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getSegmentation2();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getPreferredpartner();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getEmaster();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getLastvisit();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTaxid();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      36,
      f,
      model_order_pb.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.model.Company.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string edvNr = 2;
 * @return {string}
 */
proto.model.Company.prototype.getEdvnr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setEdvnr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string universalId = 3;
 * @return {string}
 */
proto.model.Company.prototype.getUniversalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setUniversalid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string mcCode = 4;
 * @return {string}
 */
proto.model.Company.prototype.getMccode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setMccode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string salutation = 5;
 * @return {string}
 */
proto.model.Company.prototype.getSalutation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setSalutation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.model.Company.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string nameExtend = 7;
 * @return {string}
 */
proto.model.Company.prototype.getNameextend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setNameextend = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string salesDistrict = 8;
 * @return {string}
 */
proto.model.Company.prototype.getSalesdistrict = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setSalesdistrict = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string targetGroup = 9;
 * @return {string}
 */
proto.model.Company.prototype.getTargetgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setTargetgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string subTargetGroup = 10;
 * @return {string}
 */
proto.model.Company.prototype.getSubtargetgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setSubtargetgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated string authority = 33;
 * @return {!Array<string>}
 */
proto.model.Company.prototype.getAuthorityList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 33));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setAuthorityList = function(value) {
  return jspb.Message.setField(this, 33, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.addAuthority = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 33, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.clearAuthorityList = function() {
  return this.setAuthorityList([]);
};


/**
 * optional string phone = 23;
 * @return {string}
 */
proto.model.Company.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string mobile = 24;
 * @return {string}
 */
proto.model.Company.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string fax = 25;
 * @return {string}
 */
proto.model.Company.prototype.getFax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setFax = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string mail = 26;
 * @return {string}
 */
proto.model.Company.prototype.getMail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setMail = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string homepage = 27;
 * @return {string}
 */
proto.model.Company.prototype.getHomepage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setHomepage = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string country = 28;
 * @return {string}
 */
proto.model.Company.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string city = 29;
 * @return {string}
 */
proto.model.Company.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string street = 30;
 * @return {string}
 */
proto.model.Company.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string zipCode = 31;
 * @return {string}
 */
proto.model.Company.prototype.getZipcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setZipcode = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string district = 32;
 * @return {string}
 */
proto.model.Company.prototype.getDistrict = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setDistrict = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * repeated Contact contact = 11;
 * @return {!Array<!proto.model.Contact>}
 */
proto.model.Company.prototype.getContactList = function() {
  return /** @type{!Array<!proto.model.Contact>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.Contact, 11));
};


/**
 * @param {!Array<!proto.model.Contact>} value
 * @return {!proto.model.Company} returns this
*/
proto.model.Company.prototype.setContactList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.model.Contact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.Contact}
 */
proto.model.Company.prototype.addContact = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.model.Contact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.clearContactList = function() {
  return this.setContactList([]);
};


/**
 * optional string segmentation = 14;
 * @return {string}
 */
proto.model.Company.prototype.getSegmentation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setSegmentation = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional float segmentation1 = 15;
 * @return {number}
 */
proto.model.Company.prototype.getSegmentation1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setSegmentation1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float segmentation2 = 16;
 * @return {number}
 */
proto.model.Company.prototype.getSegmentation2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setSegmentation2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional bool preferredPartner = 17;
 * @return {boolean}
 */
proto.model.Company.prototype.getPreferredpartner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setPreferredpartner = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool eMaster = 18;
 * @return {boolean}
 */
proto.model.Company.prototype.getEmaster = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setEmaster = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional google.protobuf.Timestamp lastVisit = 19;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.Company.prototype.getLastvisit = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 19));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.Company} returns this
*/
proto.model.Company.prototype.setLastvisit = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.clearLastvisit = function() {
  return this.setLastvisit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Company.prototype.hasLastvisit = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string taxId = 20;
 * @return {string}
 */
proto.model.Company.prototype.getTaxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.setTaxid = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 34;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.Company.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 34));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.Company} returns this
*/
proto.model.Company.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Company.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 35;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.Company.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 35));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.Company} returns this
*/
proto.model.Company.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Company.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * repeated Order orders = 36;
 * @return {!Array<!proto.model.Order>}
 */
proto.model.Company.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.model.Order>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_order_pb.Order, 36));
};


/**
 * @param {!Array<!proto.model.Order>} value
 * @return {!proto.model.Company} returns this
*/
proto.model.Company.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 36, value);
};


/**
 * @param {!proto.model.Order=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.Order}
 */
proto.model.Company.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 36, opt_value, proto.model.Order, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.Company} returns this
 */
proto.model.Company.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.Contact.repeatedFields_ = [12,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.Contact.prototype.toObject = function(opt_includeInstance) {
  return proto.model.Contact.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.Contact} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Contact.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    edvnr: jspb.Message.getFieldWithDefault(msg, 2, ""),
    universalid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    personid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    salutation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    title: jspb.Message.getFieldWithDefault(msg, 6, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 8, ""),
    division: jspb.Message.getFieldWithDefault(msg, 9, ""),
    quality: jspb.Message.getFieldWithDefault(msg, 10, 0),
    mybuschjaegerusername: jspb.Message.getFieldWithDefault(msg, 11, ""),
    kindsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    phone: jspb.Message.getFieldWithDefault(msg, 13, ""),
    fax: jspb.Message.getFieldWithDefault(msg, 14, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 15, ""),
    mail: jspb.Message.getFieldWithDefault(msg, 16, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    companiesList: jspb.Message.toObjectList(msg.getCompaniesList(),
    proto.model.Company.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.Contact}
 */
proto.model.Contact.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.Contact;
  return proto.model.Contact.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.Contact} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.Contact}
 */
proto.model.Contact.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdvnr(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniversalid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalutation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDivision(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuality(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMybuschjaegerusername(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addKinds(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setFax(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setMail(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 18:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 19:
      var value = new proto.model.Company;
      reader.readMessage(value,proto.model.Company.deserializeBinaryFromReader);
      msg.addCompanies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.Contact.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.Contact.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.Contact} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Contact.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEdvnr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUniversalid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPersonid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSalutation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDivision();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getQuality();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getMybuschjaegerusername();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getKindsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getFax();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getMail();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCompaniesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.model.Company.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.model.Contact.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string edvNr = 2;
 * @return {string}
 */
proto.model.Contact.prototype.getEdvnr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.setEdvnr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string universalId = 3;
 * @return {string}
 */
proto.model.Contact.prototype.getUniversalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.setUniversalid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string personId = 4;
 * @return {string}
 */
proto.model.Contact.prototype.getPersonid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.setPersonid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string salutation = 5;
 * @return {string}
 */
proto.model.Contact.prototype.getSalutation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.setSalutation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.model.Contact.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string firstname = 7;
 * @return {string}
 */
proto.model.Contact.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string lastname = 8;
 * @return {string}
 */
proto.model.Contact.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string division = 9;
 * @return {string}
 */
proto.model.Contact.prototype.getDivision = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.setDivision = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 quality = 10;
 * @return {number}
 */
proto.model.Contact.prototype.getQuality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.setQuality = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string mybuschjaegerUsername = 11;
 * @return {string}
 */
proto.model.Contact.prototype.getMybuschjaegerusername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.setMybuschjaegerusername = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated string kinds = 12;
 * @return {!Array<string>}
 */
proto.model.Contact.prototype.getKindsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.setKindsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.addKinds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.clearKindsList = function() {
  return this.setKindsList([]);
};


/**
 * optional string phone = 13;
 * @return {string}
 */
proto.model.Contact.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string fax = 14;
 * @return {string}
 */
proto.model.Contact.prototype.getFax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.setFax = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string mobile = 15;
 * @return {string}
 */
proto.model.Contact.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string mail = 16;
 * @return {string}
 */
proto.model.Contact.prototype.getMail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.setMail = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.Contact.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.Contact} returns this
*/
proto.model.Contact.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Contact.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 18;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.Contact.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 18));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.Contact} returns this
*/
proto.model.Contact.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Contact.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * repeated Company companies = 19;
 * @return {!Array<!proto.model.Company>}
 */
proto.model.Contact.prototype.getCompaniesList = function() {
  return /** @type{!Array<!proto.model.Company>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.Company, 19));
};


/**
 * @param {!Array<!proto.model.Company>} value
 * @return {!proto.model.Contact} returns this
*/
proto.model.Contact.prototype.setCompaniesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.model.Company=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.Company}
 */
proto.model.Contact.prototype.addCompanies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.model.Company, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.Contact} returns this
 */
proto.model.Contact.prototype.clearCompaniesList = function() {
  return this.setCompaniesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.Adm.prototype.toObject = function(opt_includeInstance) {
  return proto.model.Adm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.Adm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Adm.toObject = function(includeInstance, msg) {
  var f, obj = {
    region: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    salesdistrict: jspb.Message.getFieldWithDefault(msg, 4, ""),
    structureid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    legacycontactid: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.Adm}
 */
proto.model.Adm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.Adm;
  return proto.model.Adm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.Adm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.Adm}
 */
proto.model.Adm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalesdistrict(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStructureid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLegacycontactid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.Adm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.Adm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.Adm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Adm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSalesdistrict();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStructureid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLegacycontactid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string region = 1;
 * @return {string}
 */
proto.model.Adm.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Adm} returns this
 */
proto.model.Adm.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string firstname = 2;
 * @return {string}
 */
proto.model.Adm.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Adm} returns this
 */
proto.model.Adm.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastname = 3;
 * @return {string}
 */
proto.model.Adm.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Adm} returns this
 */
proto.model.Adm.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string salesDistrict = 4;
 * @return {string}
 */
proto.model.Adm.prototype.getSalesdistrict = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Adm} returns this
 */
proto.model.Adm.prototype.setSalesdistrict = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 structureID = 5;
 * @return {number}
 */
proto.model.Adm.prototype.getStructureid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.Adm} returns this
 */
proto.model.Adm.prototype.setStructureid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 legacyContactID = 6;
 * @return {number}
 */
proto.model.Adm.prototype.getLegacycontactid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.Adm} returns this
 */
proto.model.Adm.prototype.setLegacycontactid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.StructureAssignment.prototype.toObject = function(opt_includeInstance) {
  return proto.model.StructureAssignment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.StructureAssignment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.StructureAssignment.toObject = function(includeInstance, msg) {
  var f, obj = {
    contact: jspb.Message.getFieldWithDefault(msg, 1, 0),
    structure: jspb.Message.getFieldWithDefault(msg, 2, 0),
    responsibility: jspb.Message.getFieldWithDefault(msg, 3, 0),
    action: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.StructureAssignment}
 */
proto.model.StructureAssignment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.StructureAssignment;
  return proto.model.StructureAssignment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.StructureAssignment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.StructureAssignment}
 */
proto.model.StructureAssignment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setContact(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResponsibility(value);
      break;
    case 4:
      var value = /** @type {!proto.model.StructureAssignment.AssignmentType} */ (reader.readEnum());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.StructureAssignment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.StructureAssignment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.StructureAssignment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.StructureAssignment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContact();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getResponsibility();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.model.StructureAssignment.AssignmentType = {
  INSERT: 0,
  UPDATE: 1
};

/**
 * optional int32 contact = 1;
 * @return {number}
 */
proto.model.StructureAssignment.prototype.getContact = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.StructureAssignment} returns this
 */
proto.model.StructureAssignment.prototype.setContact = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 structure = 2;
 * @return {number}
 */
proto.model.StructureAssignment.prototype.getStructure = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.StructureAssignment} returns this
 */
proto.model.StructureAssignment.prototype.setStructure = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 responsibility = 3;
 * @return {number}
 */
proto.model.StructureAssignment.prototype.getResponsibility = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.StructureAssignment} returns this
 */
proto.model.StructureAssignment.prototype.setResponsibility = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional AssignmentType action = 4;
 * @return {!proto.model.StructureAssignment.AssignmentType}
 */
proto.model.StructureAssignment.prototype.getAction = function() {
  return /** @type {!proto.model.StructureAssignment.AssignmentType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.model.StructureAssignment.AssignmentType} value
 * @return {!proto.model.StructureAssignment} returns this
 */
proto.model.StructureAssignment.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.SalesDistrictResponsibilities.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.SalesDistrictResponsibilities.prototype.toObject = function(opt_includeInstance) {
  return proto.model.SalesDistrictResponsibilities.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.SalesDistrictResponsibilities} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.SalesDistrictResponsibilities.toObject = function(includeInstance, msg) {
  var f, obj = {
    listingList: jspb.Message.toObjectList(msg.getListingList(),
    proto.model.SalesDistrictResponsibility.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.SalesDistrictResponsibilities}
 */
proto.model.SalesDistrictResponsibilities.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.SalesDistrictResponsibilities;
  return proto.model.SalesDistrictResponsibilities.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.SalesDistrictResponsibilities} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.SalesDistrictResponsibilities}
 */
proto.model.SalesDistrictResponsibilities.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.SalesDistrictResponsibility;
      reader.readMessage(value,proto.model.SalesDistrictResponsibility.deserializeBinaryFromReader);
      msg.addListing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.SalesDistrictResponsibilities.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.SalesDistrictResponsibilities.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.SalesDistrictResponsibilities} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.SalesDistrictResponsibilities.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.model.SalesDistrictResponsibility.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SalesDistrictResponsibility listing = 1;
 * @return {!Array<!proto.model.SalesDistrictResponsibility>}
 */
proto.model.SalesDistrictResponsibilities.prototype.getListingList = function() {
  return /** @type{!Array<!proto.model.SalesDistrictResponsibility>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.SalesDistrictResponsibility, 1));
};


/**
 * @param {!Array<!proto.model.SalesDistrictResponsibility>} value
 * @return {!proto.model.SalesDistrictResponsibilities} returns this
*/
proto.model.SalesDistrictResponsibilities.prototype.setListingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.model.SalesDistrictResponsibility=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.SalesDistrictResponsibility}
 */
proto.model.SalesDistrictResponsibilities.prototype.addListing = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.model.SalesDistrictResponsibility, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.SalesDistrictResponsibilities} returns this
 */
proto.model.SalesDistrictResponsibilities.prototype.clearListingList = function() {
  return this.setListingList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.SalesDistrictResponsibility.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.SalesDistrictResponsibility.prototype.toObject = function(opt_includeInstance) {
  return proto.model.SalesDistrictResponsibility.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.SalesDistrictResponsibility} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.SalesDistrictResponsibility.toObject = function(includeInstance, msg) {
  var f, obj = {
    officerList: jspb.Message.toObjectList(msg.getOfficerList(),
    proto.model.Adm.toObject, includeInstance),
    budgethelperList: jspb.Message.toObjectList(msg.getBudgethelperList(),
    proto.model.Adm.toObject, includeInstance),
    salesdistrict: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.SalesDistrictResponsibility}
 */
proto.model.SalesDistrictResponsibility.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.SalesDistrictResponsibility;
  return proto.model.SalesDistrictResponsibility.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.SalesDistrictResponsibility} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.SalesDistrictResponsibility}
 */
proto.model.SalesDistrictResponsibility.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.Adm;
      reader.readMessage(value,proto.model.Adm.deserializeBinaryFromReader);
      msg.addOfficer(value);
      break;
    case 2:
      var value = new proto.model.Adm;
      reader.readMessage(value,proto.model.Adm.deserializeBinaryFromReader);
      msg.addBudgethelper(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalesdistrict(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.SalesDistrictResponsibility.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.SalesDistrictResponsibility.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.SalesDistrictResponsibility} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.SalesDistrictResponsibility.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfficerList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.model.Adm.serializeBinaryToWriter
    );
  }
  f = message.getBudgethelperList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.model.Adm.serializeBinaryToWriter
    );
  }
  f = message.getSalesdistrict();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated Adm officer = 1;
 * @return {!Array<!proto.model.Adm>}
 */
proto.model.SalesDistrictResponsibility.prototype.getOfficerList = function() {
  return /** @type{!Array<!proto.model.Adm>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.Adm, 1));
};


/**
 * @param {!Array<!proto.model.Adm>} value
 * @return {!proto.model.SalesDistrictResponsibility} returns this
*/
proto.model.SalesDistrictResponsibility.prototype.setOfficerList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.model.Adm=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.Adm}
 */
proto.model.SalesDistrictResponsibility.prototype.addOfficer = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.model.Adm, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.SalesDistrictResponsibility} returns this
 */
proto.model.SalesDistrictResponsibility.prototype.clearOfficerList = function() {
  return this.setOfficerList([]);
};


/**
 * repeated Adm budgetHelper = 2;
 * @return {!Array<!proto.model.Adm>}
 */
proto.model.SalesDistrictResponsibility.prototype.getBudgethelperList = function() {
  return /** @type{!Array<!proto.model.Adm>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.Adm, 2));
};


/**
 * @param {!Array<!proto.model.Adm>} value
 * @return {!proto.model.SalesDistrictResponsibility} returns this
*/
proto.model.SalesDistrictResponsibility.prototype.setBudgethelperList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.model.Adm=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.Adm}
 */
proto.model.SalesDistrictResponsibility.prototype.addBudgethelper = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.model.Adm, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.SalesDistrictResponsibility} returns this
 */
proto.model.SalesDistrictResponsibility.prototype.clearBudgethelperList = function() {
  return this.setBudgethelperList([]);
};


/**
 * optional string salesDistrict = 3;
 * @return {string}
 */
proto.model.SalesDistrictResponsibility.prototype.getSalesdistrict = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.SalesDistrictResponsibility} returns this
 */
proto.model.SalesDistrictResponsibility.prototype.setSalesdistrict = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


goog.object.extend(exports, proto.model);
