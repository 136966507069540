import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Button, Header, Input, Modal } from 'semantic-ui-react'
import { Text, translate } from 'preact-i18n'
import translation from '../../assets/i18n/de_DE.json'

export default function SaveFiltermaskDialog({ open, setOpen, currentFilter, saveUserFilter }) {
    const [inputValue, setInputValue] = useState('')

    const handleInput = (e) => {
        setInputValue(e.target.value)
    }

    const handleSubmit = () => {
        saveUserFilter(inputValue)
        setOpen(false)
    }

    return (
        <Modal
            mountNode={document.getElementById('content')}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
        >
            <Modal.Header>
                <Text id="saveFiltermaskDialog.header">Filtermaske speichern</Text>
            </Modal.Header>
            <Modal.Content>
                <Text id="saveFiltermaskDialog.inupt.label">Name</Text>
                <br />
                <Input
                    value={inputValue}
                    onChange={handleInput}
                    placeholder={translate('saveFiltermaskDialog.inputfield.placeholder', '', translation)}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setOpen(false)}>
                    <Text id="saveFiltermaskDialog.cancel">Abbrechen</Text>
                </Button>
                <Button
                    content={<Text id="saveFiltermaskDialog.ok">Speichern</Text>}
                    labelPosition="right"
                    icon="checkmark"
                    onClick={handleSubmit}
                    positive
                />
            </Modal.Actions>
        </Modal>
    )
}
