import { Component, h } from 'preact'

import { connect } from 'react-redux'

import {
    Container,
    Segment,
    Tab,
    Menu,
    Label,
    Icon,
    Header,
    List,
    Grid,
    Form,
    Message,
    Card,
    Pagination,
    Button,
    Modal,
    Dropdown,
    Divider,
} from 'semantic-ui-react'

import { Text, MarkupText } from 'preact-i18n'

import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { StructureAssignment, Adm } from '../proto/compiled/model/crm_pb.js'
import { Structure } from '../proto/compiled/model/structure_pb.js'

const ADM_RESPONSIBILITIES = [
    {
        key: 1,
        text: 'Verantwortlicher',
        value: 1,
    },
    {
        key: 2,
        text: 'Sichtberechtigter',
        value: 2,
    },
    {
        key: 3,
        text: 'Vertreter in Budgetangelegenheiten',
        value: 3,
    },
]

class AdmListing extends Component {
    constructor(props) {
        super(props)

        this.state = {
            creatingNewRegion: false,
            userAddedParentRegions: [],
            regionWithoutContact: [],
            activePageContactlessRegions: 0,
            legacyADMS: [],
            admListing: [],
            legacyStructures: [],
            legacyResponsibilities: null,
            legacyResponsibilityTab: 0,
            isStreaming: {
                crmData: false,
                legacyData: false,
                legacyADMS: false,
                legacyResponsibilities: false,
            },
            loaded: {
                crmData: false,
                legacyData: false,
                legacyADMS: false,
                legacyResponsibilities: false,
            },
            saving: {
                admresponsibility: false,
            },
            error: {
                structureCreation: null,
            },
            showStructureAssignModal: false,
            modalSelectedStructure: null,
            structureAssignment: {
                contact: null,
                responsibility: 0,
            },
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // ensure accestoken exists to call grpc
        if (nextProps.accesstoken == null) {
            return false
        }

        if (!this.state.isStreaming.crmData && !this.state.loaded.crmData) {
            this.fetchCrmData(nextProps.accesstoken)
            return true
        }

        if (!this.state.isStreaming.legacyData && !this.state.loaded.legacyData) {
            this.fetchLegacyData(nextProps.accesstoken)
            return true
        }

        if (!this.state.isStreaming.legacyADMS && !this.state.loaded.legacyADMS) {
            this.queryLegacyAdms(nextProps.accesstoken)
            return true
        }

        if (!this.state.isStreaming.legacyResponsibilities && !this.state.loaded.legacyResponsibilities) {
            this.getLegacyResponsibilities()
            return true
        }

        return true
    }

    componentDidMount() {
        this.setState({
            admListing: []
        })
    }

    fetchLegacyData = (accesstoken) => {
        const stream = this.props.grpcclient.getLegacyStructures(new Empty(), {
            'x-auth-ispf': accesstoken,
        })

        this.setState({
            regionWithoutContact: [],
            legacyStructures: [],
            isStreaming: {
                ...this.state.isStreaming,
                legacyData: true,
            },
        })

        stream.on('status', (stat) => {
            if (stat.code == 16) {
                // Unauthorized
                window.location = '/'
                return
            }
        })

        stream.on('data', (res) => {
            if (res.getFirstname() == '' && res.getLastname() == '') {
                this.setState(({ regionWithoutContact }) => regionWithoutContact.push(res))
            } else {
                this.setState(({ legacyStructures }) => legacyStructures.push(res))
            }
        })

        stream.on('end', (res) => {
            this.setState({
                isStreaming: {
                    ...this.state.isStreaming,
                    legacyData: false,
                },
                loaded: {
                    ...this.state.loaded,
                    legacyData: true,
                },
            })
        })
    }

    fetchCrmData = (accesstoken) => {
        // Begin fetching ADMS
        const stream = this.props.grpcclient.getADMS(new Empty(), {
            'x-auth-ispf': accesstoken,
        })

        this.setState({
            isStreaming: {
                ...this.state.isStreaming,
                crmData: true,
            },
        })

        stream.on('status', (stat) => {
            if (stat.code != 0) {
                // Unauthorized
                window.location = '/'
            }
        })

        stream.on('data', (res) => {
            this.setState(({ admListing }) => admListing.push(res))
        })

        stream.on('end', (res) => {
            this.setState({
                isStreaming: {
                    ...this.state.isStreaming,
                    crmData: false,
                },
                loaded: {
                    ...this.state.loaded,
                    crmData: true,
                },
            })
        })
    }

    changeStructure = (action, data) => {
        switch (action) {
            case 'delete_assignment':
                this.props.grpcclient.removeStructureADM(
                    data,
                    {
                        'x-auth-ispf': this.props.accesstoken,
                    },
                    (err, res) => {
                        if (err === null) {
                            this.fetchLegacyData(this.props.accesstoken)
                        }
                    }
                )
                break
            case 'delete_structure':
                this.props.grpcclient.deleteStructure(
                    data,
                    {
                        'x-auth-ispf': this.props.accesstoken,
                    },
                    (err, res) => {
                        if (err === null) {
                            this.fetchLegacyData(this.props.accesstoken)
                            this.queryLegacyAdms(this.props.accesstoken)
                        }
                    }
                )
                break
            case 'assign_adm':
                this.setState({
                    showStructureAssignModal: true,
                    modalSelectedStructure: data,
                })
                break
        }
    }

    districtRender = (district, deleteAssignment) => {
        return (
            <List divided relaxed>
                {district.children.map((ele) => (
                    <List.Item>
                        <List.Content>
                            <List.Header>
                                {ele.getFirstname()} {ele.getLastname()}
                            </List.Header>
                            <List.Description>{ele.getRegion()}</List.Description>
                            {deleteAssignment && (
                                <Button
                                    basic
                                    color="red"
                                    floated="right"
                                    onClick={() => this.changeStructure('delete_assignment', ele)}
                                >
                                    <Text id="STRUCTURES.delete_assignment" />
                                </Button>
                            )}
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        )
    }

    chunkArray = (arr, size) => {
        return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size))
    }

    regionWithoutContactCards = (state, datasrc) => {
        const itemsPerPage = 8
        const contactless = this.chunkArray(this.state.regionWithoutContact, itemsPerPage)

        return (
            <Grid>
                <Grid.Row centered>
                    <Segment basic loading={this.state.regionWithoutContact.length < 1}>
                        <Card.Group itemsPerRow={2}>
                            {contactless[this.state.activePageContactlessRegions] &&
                                contactless[this.state.activePageContactlessRegions].map((ele) => (
                                    <Card>
                                        <Card.Content textAlign={'left'}>
                                            <Card.Header>{ele.getSalesdistrict()}</Card.Header>
                                            <Card.Meta>{ele.getRegion()}</Card.Meta>
                                            <Card.Description>
                                                <Icon name="user" /> 9999999999999
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content extra>
                                            <Button.Group fluid>
                                                <Button
                                                    onClick={() => this.changeStructure('delete_structure', ele)}
                                                    basic
                                                    color="red"
                                                >
                                                    <Text id="delete_btn" />
                                                </Button>
                                                <Button
                                                    onClick={() => this.changeStructure('assign_adm', ele)}
                                                    basic
                                                    color="green"
                                                >
                                                    <Text id="STRUCTURES.assign_btn" />
                                                </Button>
                                            </Button.Group>
                                        </Card.Content>
                                    </Card>
                                ))}
                        </Card.Group>
                    </Segment>
                </Grid.Row>
                <Grid.Row centered>
                    <Pagination
                        onPageChange={(e, { activePage }) =>
                            this.setState({ activePageContactlessRegions: activePage - 1 })
                        }
                        totalPages={contactless.length}
                    />
                </Grid.Row>
            </Grid>
        )
    }

    panes = (state, datasrc) => {
        const pane = []
        const renderPanes = []

        state[datasrc].map((ele) => {
            const salesDistrict = ele.getSalesdistrict()
            if (!pane.hasOwnProperty(salesDistrict)) {
                pane[salesDistrict] = {
                    children: [],
                }
                pane[salesDistrict].render = () => (
                    <Tab.Pane>{this.districtRender(pane[salesDistrict], datasrc === 'legacyStructures')}</Tab.Pane>
                )

                renderPanes.push(pane[salesDistrict])
            }

            pane[salesDistrict].children.push(ele)

            pane[salesDistrict].menuItem = (
                <Menu.Item>
                    {salesDistrict === '' ? <Text id="ADM.without_sales_district" /> : salesDistrict}
                    <Label color="teal">
                        <Icon name="users" />
                        {pane[salesDistrict].children.length}
                    </Label>
                </Menu.Item>
            )
        })

        return (
            <Tab
                grid={{ paneWidth: 8, tabWidth: 8 }}
                menu={{ fluid: true, vertical: true, tabular: true }}
                panes={renderPanes}
            />
        )
    }

    responsibilityPane = () => {
        const currentIndex = this.state.legacyResponsibilityTab
        if (this.state.legacyResponsibilities !== null) {
            const panes = this.state.legacyResponsibilities
                .getListingList()
                .sort((a, b) => {
                    if (a.getSalesdistrict() < b.getSalesdistrict()) {
                        return -1
                    }
                    if (a.getSalesdistrict() > b.getSalesdistrict()) {
                        return 1
                    }
                    return 0
                })
                .map((ele) => ({
                    menuItem: ele.getSalesdistrict(),
                    render: () => (
                        <Tab.Pane>
                            <Button
                                fluid
                                color="blue"
                                attached="top"
                                onClick={() => {
                                    this.setState({
                                        modalSelectedStructure: ele.getOfficerList()[0] || ele.getBudgethelperList()[0],
                                    })
                                    this.changeStructure(
                                        'assign_adm',
                                        ele.getOfficerList()[0] || ele.getBudgethelperList()[0]
                                    )
                                }}
                            >
                                <Text id="STRUCTURES.assign_btn" />
                            </Button>
                            <Divider fitted inverted />
                            <Button
                                fluid
                                color="red"
                                attached="bottom"
                                onClick={() => {
                                    this.setState({
                                        legacyResponsibilities: null,
                                        isStreaming: {
                                            ...this.state.isStreaming,
                                            legacyResponsibilities: false,
                                        },
                                        loaded: {
                                            ...this.state.loaded,
                                            legacyResponsibilities: false,
                                        },
                                    })
                                    this.changeStructure(
                                        'delete_structure',
                                        ele.getOfficerList()[0] || ele.getBudgethelperList()[0]
                                    )
                                }}
                            >
                                <Text id="delete_btn" />
                            </Button>
                            {ele.getOfficerList().map((ele) => (
                                <Segment raised>
                                    <Label color="teal" ribbon>
                                        {ADM_RESPONSIBILITIES[0].text}
                                    </Label>
                                    <Header size={'huge'}>
                                        {ele.getFirstname()} {ele.getLastname()}
                                    </Header>
                                    <Label basic color="teal" pointing="right">
                                        <Text id="STRUCTURES.assign_new_responsibility" fields={{ what: '' }} />
                                    </Label>
                                    <Dropdown
                                        inline
                                        search
                                        options={this.state.legacyADMS}
                                        placeholder="Select"
                                        onChange={(e, { value }) => {
                                            const adm = new Adm()
                                            adm.setStructureid(ele.getStructureid())

                                            this.setState({
                                                structureAssignment: {
                                                    contact: value,
                                                    responsibility: 1,
                                                },
                                                modalSelectedStructure: adm,
                                            })
                                        }}
                                    />
                                    <Button
                                        basic
                                        color="blue"
                                        onClick={() =>
                                            this.assignAdm(
                                                ele.getStructureid() != 0 && ele.getLegacycontactid() == 0
                                                    ? StructureAssignment.AssignmentType.INSERT
                                                    : StructureAssignment.AssignmentType.UPDATE
                                            )
                                        }
                                        content={<Text id="save_btn" />}
                                    />
                                    <Button
                                        basic
                                        color="red"
                                        icon="trash"
                                        onClick={() => {
                                            this.setState({
                                                legacyResponsibilities: null,
                                                isStreaming: {
                                                    ...this.state.isStreaming,
                                                    legacyResponsibilities: false,
                                                },
                                                loaded: {
                                                    ...this.state.loaded,
                                                    legacyResponsibilities: false,
                                                },
                                            })
                                            this.changeStructure('delete_assignment', ele)
                                        }}
                                    ></Button>
                                </Segment>
                            ))}
                            {ele.getBudgethelperList().map((ele) => (
                                <Segment raised>
                                    <Label color="orange" ribbon>
                                        {ADM_RESPONSIBILITIES[2].text}
                                    </Label>
                                    <Header size={'huge'}>
                                        {ele.getFirstname()} {ele.getLastname()}
                                    </Header>
                                    <Label basic color="orange" pointing="right">
                                        <Text id="STRUCTURES.assign_new_responsibility" fields={{ what: '' }} />
                                    </Label>
                                    <Dropdown
                                        inline
                                        search
                                        options={this.state.legacyADMS}
                                        placeholder="Select"
                                        onChange={(e, { value }) => {
                                            const adm = new Adm()
                                            adm.setStructureid(ele.getStructureid())
                                            if (ele.getStructureid() === 0) {
                                                adm.setStructureid(ele.getOfficer().getStructureid())
                                            }
                                            this.setState({
                                                structureAssignment: {
                                                    contact: value,
                                                    responsibility: 3,
                                                },
                                                modalSelectedStructure: adm,
                                            })
                                        }}
                                    />
                                    <Button
                                        basic
                                        color="blue"
                                        onClick={() =>
                                            this.assignAdm(
                                                ele.getStructureid() != 0 && ele.getLegacycontactid() == 0
                                                    ? StructureAssignment.AssignmentType.INSERT
                                                    : StructureAssignment.AssignmentType.UPDATE
                                            )
                                        }
                                    >
                                        <Text id="save_btn" />
                                    </Button>
                                    <Button
                                        basic
                                        color="red"
                                        icon="trash"
                                        onClick={() => {
                                            this.setState({
                                                legacyResponsibilities: null,
                                                isStreaming: {
                                                    ...this.state.isStreaming,
                                                    legacyResponsibilities: false,
                                                },
                                                loaded: {
                                                    ...this.state.loaded,
                                                    legacyResponsibilities: false,
                                                },
                                            })
                                            this.changeStructure('delete_assignment', ele)
                                        }}
                                    ></Button>
                                </Segment>
                            ))}
                        </Tab.Pane>
                    ),
                }))

            return (
                <Tab
                    menu={{
                        fluid: true,
                        vertical: true,
                        tabular: 'right',
                    }}
                    panes={panes}
                    onTabChange={(e, { activeIndex }) => this.setState({ legacyResponsibilityTab: activeIndex })}
                    activeIndex={currentIndex}
                />
            )
        }
    }

    getLegacyResponsibilities = () => {
        const meta = {
            'x-auth-ispf': this.props.accesstoken,
        }

        this.setState({
            legacyResponsibilities: null,
            isStreaming: {
                ...this.state.isStreaming,
                legacyResponsibilities: true,
            },
        })

        this.props.grpcclient.getSalesDistrictResponsibilities(new Empty(), meta, (err, res) => {
            if (err === null) {
                this.setState({
                    legacyResponsibilities: res,
                    isStreaming: {
                        ...this.state.isStreaming,
                        legacyResponsibilities: false,
                    },
                    loaded: {
                        ...this.state.loaded,
                        legacyResponsibilities: true,
                    }
                })
            }
        })
    }

    getEpParentRegions = () => {
        const parents = []
        const mapped = [...this.state.userAddedParentRegions]

        this.state.legacyStructures.forEach((ele) => {
            const salesDistrict = ele.getSalesdistrict()
            if (mapped.indexOf(salesDistrict) === -1) {
                mapped[mapped.length] = salesDistrict
            }
        })

        mapped.sort().forEach((ele) => {
            parents[parents.length] = {
                key: ele,
                value: ele,
                text: ele,
            }
        })

        return parents
    }

    handleNewRegionChange = (e, { name, value }) => this.setState({ [name]: value })

    handleParentRegionAddition = (e, { value }) =>
        this.setState(({ userAddedParentRegions }) => userAddedParentRegions.push(value))

    submitNewRegion = () => {
        const { newregion, newparent } = this.state
        this.setState({ creatingNewRegion: true })

        const req = new Structure()
        req.setSalesdistrict(newparent)
        req.setRegion(newregion)

        const meta = {
            'x-auth-ispf': this.props.accesstoken,
        }

        this.props.grpcclient.createStructure(req, meta, (err, res) => {
            this.setState({
                error: {
                    ...this.state.error,
                    structureCreation: err !== null ? err.message : null,
                },
                creatingNewRegion: false,
            })

            if (err === null) {
                this.fetchLegacyData(this.props.accesstoken)
            }
        })

        this.setState({ newregion: '', newparent: '' })
    }

    queryLegacyAdms = (accesstoken) => {
        // Begin fetching ADMS
        const stream = this.props.grpcclient.getLegacyADMS(new Empty(), {
            'x-auth-ispf': accesstoken,
        })

        this.setState({
            legacyADMS: [],
            isStreaming: {
                ...this.state.isStreaming,
                legacyADMS: true,
            },
        })

        stream.on('status', (stat) => {
            if (stat.code != 0) {
                // Unauthorized
                window.location = '/'
            }
        })

        stream.on('data', (res) => {
            this.setState(({ legacyADMS }) =>
                legacyADMS.push({
                    key: res.getLegacycontactid(),
                    text: `${res.getFirstname()} ${res.getLastname()}`,
                    value: res.getLegacycontactid(),
                })
            )
        })

        stream.on('end', (res) => {
            this.setState({
                isStreaming: {
                    ...this.state.isStreaming,
                    legacyADMS: false,
                },
                loaded: {
                    ...this.state.loaded,
                    legacyADMS: true,
                },
            })
        })
    }

    assignAdm = (actionType) => {
        const { contact, responsibility } = this.state.structureAssignment

        if (contact === null || responsibility === 0) {
            return
        }

        this.setState({
            saving: { admresponsibility: true },
            legacyResponsibilities: null,
            loaded: {
                ...this.state.loaded,
                legacyResponsibilities: false,
            },
        })

        const assignment = new StructureAssignment()
        assignment.setContact(contact)
        assignment.setStructure(this.state.modalSelectedStructure.getStructureid())
        assignment.setResponsibility(responsibility)

        if (actionType) {
            assignment.setAction(actionType)
        }

        this.props.grpcclient.assignAdm(
            assignment,
            {
                'x-auth-ispf': this.props.accesstoken,
            },
            (err, res) => {
                if (err === null) {
                    this.fetchLegacyData(this.props.accesstoken)
                    this.queryLegacyAdms(this.props.accesstoken)
                }
            }
        )

        this.setState({ showStructureAssignModal: false, saving: { admresponsibility: false } })
    }

    render(props, state) {
        return (
            <Container fluid>
                <Modal
                    mountNode={document.getElementById('content')}
                    open={state.showStructureAssignModal}
                    closeOnDocumentClick={true}
                    closeOnDimmerClick={true}
                    onClose={() => this.setState({ showStructureAssignModal: false })}
                >
                    <Modal.Header>
                        <Text id="STRUCTURES.assign_btn" />
                    </Modal.Header>
                    <Modal.Content>
                        <MarkupText
                            id="STRUCTURES.modal.assign"
                            fields={{
                                region:
                                    state.modalSelectedStructure !== null && state.modalSelectedStructure.getRegion(),
                                parent:
                                    state.modalSelectedStructure !== null &&
                                    state.modalSelectedStructure.getSalesdistrict(),
                            }}
                        />
                        <Divider />
                        <Dropdown
                            onChange={(e, { value }) =>
                                this.setState({
                                    structureAssignment: {
                                        ...state.structureAssignment,
                                        contact: value,
                                    },
                                })
                            }
                            search
                            selection
                            fluid
                            options={state.legacyADMS}
                            placeholder={<Text id="attributes.company.authority" />}
                        />
                        <Divider />
                        <Dropdown
                            onChange={(e, { value }) =>
                                this.setState({
                                    structureAssignment: {
                                        ...state.structureAssignment,
                                        responsibility: value,
                                    },
                                })
                            }
                            search
                            selection
                            fluid
                            options={ADM_RESPONSIBILITIES}
                            placeholder={<Text id="responsibility" />}
                        />
                        <Button fluid color="blue" onClick={() => this.assignAdm()}>
                            <Text id="STRUCTURES.assign_btn" />
                        </Button>
                    </Modal.Content>
                </Modal>
                <Segment>
                    <Grid columns={2} divided>
                        <Grid.Column>
                            <Header textAlign="center">
                                <Text id="STRUCTURES.without_contact" />
                            </Header>
                            {this.regionWithoutContactCards(state, 'regionWithoutContact')}
                        </Grid.Column>
                        <Grid.Column>
                            <Header textAlign="center">
                                <Text id="STRUCTURES.create_ep_structure" />
                            </Header>
                            <Form loading={state.creatingNewRegion} onSubmit={this.submitNewRegion}>
                                <Form.Group inline>
                                    <Form.Input
                                        fluid
                                        label={<Text id="STRUCTURES.label_description" />}
                                        placeholder="Regions Bezeichnung"
                                        value={state.newregion}
                                        name="newregion"
                                        onChange={this.handleNewRegionChange}
                                    />
                                    <Form.Dropdown
                                        fluid
                                        label={<Text id="STRUCTURES.label_parent_region" />}
                                        placeholder="Lüdenscheid"
                                        options={this.getEpParentRegions()}
                                        allowAdditions
                                        additionLabel={<Text id="STRUCTURES.add_new_parent" />}
                                        selection
                                        search
                                        value={state.newparent}
                                        name="newparent"
                                        onAddItem={this.handleParentRegionAddition}
                                        onChange={this.handleNewRegionChange}
                                    />
                                </Form.Group>
                                <Message
                                    error
                                    visible={state.error.structureCreation !== null}
                                    content={<Text id={`errors.${state.error.structureCreation}`} />}
                                />
                                <Form.Button color="blue">
                                    <Text id="create" />
                                </Form.Button>
                            </Form>
                            <Divider />
                            <Header textAlign="center">
                                <Text id="STRUCTURES.edit_responsibilities" />
                            </Header>
                            {this.responsibilityPane()}
                        </Grid.Column>
                    </Grid>
                </Segment>
                <Grid columns={'equal'}>
                    <Grid.Column>
                        <Segment>
                            <Header size="medium">
                                <Text id="ADM.listing_header_as_crm" />
                            </Header>
                            {this.panes(state, 'admListing')}
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>
                            <Header size="medium">
                                <Text id="ADM.listing_header_as_excellence" />
                            </Header>
                            {this.panes(state, 'legacyStructures')}
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Container>
        )
    }
}

export default connect((state) => ({
    accesstoken: state.accesstoken,
}))(AdmListing)
