import { Component, h } from 'preact'
import { Router } from 'preact-router'
import { Provider } from 'react-redux'
import { Container, Form, Grid, Button, Transition, Segment } from 'semantic-ui-react'
import { fetchAccessToken } from '@bjep-extmod/ispf-auth'

import { store, setVisibility, setToken } from './redux/store.js'

//import * as empty from 'google-protobuf/google/protobuf/empty_pb';
import grpcclient from './proto/compiled/grpc/grpc_grpc_web_pb.js'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'

import { IntlProvider, Text } from 'preact-i18n'
import translation from './assets/i18n/de_DE.json'
import FilterGroup from './components/filterGroup.js'
import ResultSet from './components/resultset.js'
import Details from './components/detail.js'
import AdmListing from './components/admlisting.js'

import './styles.css'
import UserFilters from './components/UserFilters/userFilters.js'

const nonFilterableAttributes = ['id', 'hash', 'contactList', 'companiesList', 'createdAt', 'updatedAt']

export default class App extends Component {
    constructor(props) {
        super(props)

        // Hacky visibility toggle
        store.subscribe(() => {
            this.setState({
                visibility: store.getState().visibility,
            })
        })

        this.props.fieldsperline = 6

        this.props.crmClient = new grpcclient.CrmClient(process.env.PREACT_APP_API_URI)

        // init Filter for Company
        const search = new proto.Search()
        const company = new proto.model.Company()
        const contact = new proto.model.Contact()

        this.state = {
            filterAttributes: [],
            resultset: [],
            previousSearch: '',
            visibility: 'search',
            search: search,
            isStreaming: false,
            filterAttributes: {
                company: this.clearNonFilterableAttributes(company.toObject()),
                contact: this.clearNonFilterableAttributes(contact.toObject()),
            },
        }
    }

    clearNonFilterableAttributes = (obj) => {
        const keys = Object.keys(obj)
        const diff = nonFilterableAttributes.filter((key) => keys.includes(key))

        diff.map((key) => {
            delete obj[key]
        })

        return obj
    }

    search = () => {
        store.dispatch(setVisibility(''))

        const equalsPreviousSearch = this.state.search.toString() === this.state.previousSearch

        if (!equalsPreviousSearch) {
            const stream = this.props.crmClient.search(this.state.search, {
                'x-auth-ispf': store.getState().accesstoken,
            })

            this.setState((previousState) => ({
                resultset: [],
                previousSearch: previousState.search.toString(),
                isStreaming: true,
            }))

            stream.on('status', (stat) => {
                if (stat.code != 0) {
                    // Unauthorized
                    window.location = '/'
                }
            })

            stream.on('data', (res) => {
                this.setState((prevState) => (prevState.resultset[prevState.resultset.length] = res))
            })

            stream.on('end', (end) => {
                this.setState((previousState) => ({
                    isStreaming: false,
                }))
            })
        }
    }

    showResult = () => {
        store.dispatch(setVisibility('result'))
    }

    hideResult = () => {
        store.dispatch(setVisibility('search'))
    }

    setSearchByMask = (userFilter) => {
        this.setState({
            ...this.state,
            search: userFilter.getFilter(),
        })
    }

    render(props, state) {
        return (
            <Provider store={store}>
                <IntlProvider definition={translation}>
                    <Router>
                        <Details
                            entityType="company"
                            path="/backend/v2/mitgliedersuche/company/:id"
                            grpcclient={props.crmClient}
                        />
                        <Details entityType="contact" path="/backend/v2/mitgliedersuche/contact/:id" />
                        <Details entityType="adm" path="/backend/v2/mitgliedersuche/adm/:id" />
                        <Container fluid path="/backend/v2/mitgliedersuche/adm">
                            <AdmListing grpcclient={props.crmClient} />
                        </Container>
                        <Container fluid default>
                            <Transition
                                visible={state.visibility === 'search'}
                                animation="fade right"
                                onHide={() => this.showResult()}
                                duration={240}
                            >
                                <Grid divided="vertically">
                                    <UserFilters
                                        grpcclient={props.crmClient}
                                        currentSearch={state.search}
                                        setSearchByMask={this.setSearchByMask}
                                    />
                                    <Grid.Row columns={1}>
                                        <Grid.Column>
                                            <Form fluid>
                                                <Grid divided columns="equal" relaxed="very">
                                                    <Grid.Column>
                                                        <FilterGroup
                                                            type="company"
                                                            grpc={props.crmClient}
                                                            containerObj={state.search}
                                                            attributes={state.filterAttributes.company}
                                                            fieldsperline={props.fieldsperline}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <FilterGroup
                                                            type="contact"
                                                            grpc={props.crmClient}
                                                            containerObj={state.search}
                                                            attributes={state.filterAttributes.contact}
                                                            fieldsperline={props.fieldsperline}
                                                        />
                                                    </Grid.Column>
                                                </Grid>
                                            </Form>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={2}>
                                        <Grid.Column>
                                            <Button onClick={(ev, data) => this.search(data)}>
                                                <Text id="search">Search</Text>
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Transition>
                            <Transition visible={state.visibility === 'result'} animation="fade left">
                                <Container fluid>
                                    <ResultSet data={state.resultset} />
                                </Container>
                            </Transition>
                        </Container>
                    </Router>
                </IntlProvider>
            </Provider>
        )
    }
}
