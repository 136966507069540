// source: model/search.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var model_crm_pb = require('../model/crm_pb.js');
goog.object.extend(proto, model_crm_pb);
goog.exportSymbol('proto.model.Search', null, global);
goog.exportSymbol('proto.model.Search.IntegerSearch', null, global);
goog.exportSymbol('proto.model.Search.NumberSearchCondition', null, global);
goog.exportSymbol('proto.model.Search.SearchDetail', null, global);
goog.exportSymbol('proto.model.Search.SearchDetail.ValueCase', null, global);
goog.exportSymbol('proto.model.Search.StringListCondition', null, global);
goog.exportSymbol('proto.model.Search.StringListSearch', null, global);
goog.exportSymbol('proto.model.Search.StringSearch', null, global);
goog.exportSymbol('proto.model.Search.StringSearchCondition', null, global);
goog.exportSymbol('proto.model.Search.TimestampSearch', null, global);
goog.exportSymbol('proto.model.Search.TimestampSearchCondition', null, global);
goog.exportSymbol('proto.model.SearchResponse', null, global);
goog.exportSymbol('proto.model.SearchResponse.ResponseCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.SearchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.model.SearchResponse.oneofGroups_);
};
goog.inherits(proto.model.SearchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.SearchResponse.displayName = 'proto.model.SearchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.Search = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.Search.repeatedFields_, null);
};
goog.inherits(proto.model.Search, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.Search.displayName = 'proto.model.Search';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.Search.StringListSearch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.Search.StringListSearch.repeatedFields_, null);
};
goog.inherits(proto.model.Search.StringListSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.Search.StringListSearch.displayName = 'proto.model.Search.StringListSearch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.Search.StringSearch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.Search.StringSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.Search.StringSearch.displayName = 'proto.model.Search.StringSearch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.Search.IntegerSearch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.Search.IntegerSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.Search.IntegerSearch.displayName = 'proto.model.Search.IntegerSearch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.Search.TimestampSearch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.Search.TimestampSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.Search.TimestampSearch.displayName = 'proto.model.Search.TimestampSearch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.Search.SearchDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.model.Search.SearchDetail.oneofGroups_);
};
goog.inherits(proto.model.Search.SearchDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.Search.SearchDetail.displayName = 'proto.model.Search.SearchDetail';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.model.SearchResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.model.SearchResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  COMPANY: 2,
  CONTACT: 3
};

/**
 * @return {proto.model.SearchResponse.ResponseCase}
 */
proto.model.SearchResponse.prototype.getResponseCase = function() {
  return /** @type {proto.model.SearchResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.model.SearchResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.SearchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.SearchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.SearchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.SearchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responsetype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    company: (f = msg.getCompany()) && model_crm_pb.Company.toObject(includeInstance, f),
    contact: (f = msg.getContact()) && model_crm_pb.Contact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.SearchResponse}
 */
proto.model.SearchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.SearchResponse;
  return proto.model.SearchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.SearchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.SearchResponse}
 */
proto.model.SearchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponsetype(value);
      break;
    case 2:
      var value = new model_crm_pb.Company;
      reader.readMessage(value,model_crm_pb.Company.deserializeBinaryFromReader);
      msg.setCompany(value);
      break;
    case 3:
      var value = new model_crm_pb.Contact;
      reader.readMessage(value,model_crm_pb.Contact.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.SearchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.SearchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.SearchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.SearchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponsetype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompany();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      model_crm_pb.Company.serializeBinaryToWriter
    );
  }
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      model_crm_pb.Contact.serializeBinaryToWriter
    );
  }
};


/**
 * optional string responseType = 1;
 * @return {string}
 */
proto.model.SearchResponse.prototype.getResponsetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.SearchResponse} returns this
 */
proto.model.SearchResponse.prototype.setResponsetype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Company company = 2;
 * @return {?proto.model.Company}
 */
proto.model.SearchResponse.prototype.getCompany = function() {
  return /** @type{?proto.model.Company} */ (
    jspb.Message.getWrapperField(this, model_crm_pb.Company, 2));
};


/**
 * @param {?proto.model.Company|undefined} value
 * @return {!proto.model.SearchResponse} returns this
*/
proto.model.SearchResponse.prototype.setCompany = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.model.SearchResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.SearchResponse} returns this
 */
proto.model.SearchResponse.prototype.clearCompany = function() {
  return this.setCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.SearchResponse.prototype.hasCompany = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Contact contact = 3;
 * @return {?proto.model.Contact}
 */
proto.model.SearchResponse.prototype.getContact = function() {
  return /** @type{?proto.model.Contact} */ (
    jspb.Message.getWrapperField(this, model_crm_pb.Contact, 3));
};


/**
 * @param {?proto.model.Contact|undefined} value
 * @return {!proto.model.SearchResponse} returns this
*/
proto.model.SearchResponse.prototype.setContact = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.model.SearchResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.SearchResponse} returns this
 */
proto.model.SearchResponse.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.SearchResponse.prototype.hasContact = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.Search.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.Search.prototype.toObject = function(opt_includeInstance) {
  return proto.model.Search.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.Search} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Search.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchList: jspb.Message.toObjectList(msg.getSearchList(),
    proto.model.Search.SearchDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.Search}
 */
proto.model.Search.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.Search;
  return proto.model.Search.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.Search} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.Search}
 */
proto.model.Search.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.Search.SearchDetail;
      reader.readMessage(value,proto.model.Search.SearchDetail.deserializeBinaryFromReader);
      msg.addSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.Search.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.Search.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.Search} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Search.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.model.Search.SearchDetail.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.model.Search.StringSearchCondition = {
  CONTAINS: 0,
  BEGINS_WITH: 1,
  ENDS_WITH: 2,
  STR_EQUALS: 3
};

/**
 * @enum {number}
 */
proto.model.Search.NumberSearchCondition = {
  EQUALS: 0,
  GREATER_THAN: 1,
  LOWER_THAN: 2
};

/**
 * @enum {number}
 */
proto.model.Search.TimestampSearchCondition = {
  AT: 0,
  BEFORE: 1,
  AFTER: 2
};

/**
 * @enum {number}
 */
proto.model.Search.StringListCondition = {
  IN: 0,
  NOT_IN: 1
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.Search.StringListSearch.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.Search.StringListSearch.prototype.toObject = function(opt_includeInstance) {
  return proto.model.Search.StringListSearch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.Search.StringListSearch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Search.StringListSearch.toObject = function(includeInstance, msg) {
  var f, obj = {
    valueList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    condition: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.Search.StringListSearch}
 */
proto.model.Search.StringListSearch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.Search.StringListSearch;
  return proto.model.Search.StringListSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.Search.StringListSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.Search.StringListSearch}
 */
proto.model.Search.StringListSearch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addValue(value);
      break;
    case 2:
      var value = /** @type {!proto.model.Search.StringListCondition} */ (reader.readEnum());
      msg.setCondition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.Search.StringListSearch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.Search.StringListSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.Search.StringListSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Search.StringListSearch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValueList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCondition();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated string value = 1;
 * @return {!Array<string>}
 */
proto.model.Search.StringListSearch.prototype.getValueList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.Search.StringListSearch} returns this
 */
proto.model.Search.StringListSearch.prototype.setValueList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.Search.StringListSearch} returns this
 */
proto.model.Search.StringListSearch.prototype.addValue = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.Search.StringListSearch} returns this
 */
proto.model.Search.StringListSearch.prototype.clearValueList = function() {
  return this.setValueList([]);
};


/**
 * optional StringListCondition condition = 2;
 * @return {!proto.model.Search.StringListCondition}
 */
proto.model.Search.StringListSearch.prototype.getCondition = function() {
  return /** @type {!proto.model.Search.StringListCondition} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.model.Search.StringListCondition} value
 * @return {!proto.model.Search.StringListSearch} returns this
 */
proto.model.Search.StringListSearch.prototype.setCondition = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.Search.StringSearch.prototype.toObject = function(opt_includeInstance) {
  return proto.model.Search.StringSearch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.Search.StringSearch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Search.StringSearch.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    condition: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.Search.StringSearch}
 */
proto.model.Search.StringSearch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.Search.StringSearch;
  return proto.model.Search.StringSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.Search.StringSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.Search.StringSearch}
 */
proto.model.Search.StringSearch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.model.Search.StringSearchCondition} */ (reader.readEnum());
      msg.setCondition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.Search.StringSearch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.Search.StringSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.Search.StringSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Search.StringSearch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCondition();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.model.Search.StringSearch.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Search.StringSearch} returns this
 */
proto.model.Search.StringSearch.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional StringSearchCondition condition = 2;
 * @return {!proto.model.Search.StringSearchCondition}
 */
proto.model.Search.StringSearch.prototype.getCondition = function() {
  return /** @type {!proto.model.Search.StringSearchCondition} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.model.Search.StringSearchCondition} value
 * @return {!proto.model.Search.StringSearch} returns this
 */
proto.model.Search.StringSearch.prototype.setCondition = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.Search.IntegerSearch.prototype.toObject = function(opt_includeInstance) {
  return proto.model.Search.IntegerSearch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.Search.IntegerSearch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Search.IntegerSearch.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    condition: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.Search.IntegerSearch}
 */
proto.model.Search.IntegerSearch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.Search.IntegerSearch;
  return proto.model.Search.IntegerSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.Search.IntegerSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.Search.IntegerSearch}
 */
proto.model.Search.IntegerSearch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.model.Search.NumberSearchCondition} */ (reader.readEnum());
      msg.setCondition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.Search.IntegerSearch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.Search.IntegerSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.Search.IntegerSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Search.IntegerSearch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCondition();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 value = 1;
 * @return {number}
 */
proto.model.Search.IntegerSearch.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.Search.IntegerSearch} returns this
 */
proto.model.Search.IntegerSearch.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional NumberSearchCondition condition = 2;
 * @return {!proto.model.Search.NumberSearchCondition}
 */
proto.model.Search.IntegerSearch.prototype.getCondition = function() {
  return /** @type {!proto.model.Search.NumberSearchCondition} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.model.Search.NumberSearchCondition} value
 * @return {!proto.model.Search.IntegerSearch} returns this
 */
proto.model.Search.IntegerSearch.prototype.setCondition = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.Search.TimestampSearch.prototype.toObject = function(opt_includeInstance) {
  return proto.model.Search.TimestampSearch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.Search.TimestampSearch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Search.TimestampSearch.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    condition: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.Search.TimestampSearch}
 */
proto.model.Search.TimestampSearch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.Search.TimestampSearch;
  return proto.model.Search.TimestampSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.Search.TimestampSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.Search.TimestampSearch}
 */
proto.model.Search.TimestampSearch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.model.Search.TimestampSearchCondition} */ (reader.readEnum());
      msg.setCondition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.Search.TimestampSearch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.Search.TimestampSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.Search.TimestampSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Search.TimestampSearch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCondition();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp value = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.Search.TimestampSearch.prototype.getValue = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.Search.TimestampSearch} returns this
*/
proto.model.Search.TimestampSearch.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Search.TimestampSearch} returns this
 */
proto.model.Search.TimestampSearch.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Search.TimestampSearch.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TimestampSearchCondition condition = 2;
 * @return {!proto.model.Search.TimestampSearchCondition}
 */
proto.model.Search.TimestampSearch.prototype.getCondition = function() {
  return /** @type {!proto.model.Search.TimestampSearchCondition} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.model.Search.TimestampSearchCondition} value
 * @return {!proto.model.Search.TimestampSearch} returns this
 */
proto.model.Search.TimestampSearch.prototype.setCondition = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.model.Search.SearchDetail.oneofGroups_ = [[3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.model.Search.SearchDetail.ValueCase = {
  VALUE_NOT_SET: 0,
  STR_VALUE: 3,
  INT_VALUE: 4,
  BOOL_VALUE: 5,
  STR_LIST_VALUE: 6,
  TIMESTAMP_VALUE: 7
};

/**
 * @return {proto.model.Search.SearchDetail.ValueCase}
 */
proto.model.Search.SearchDetail.prototype.getValueCase = function() {
  return /** @type {proto.model.Search.SearchDetail.ValueCase} */(jspb.Message.computeOneofCase(this, proto.model.Search.SearchDetail.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.Search.SearchDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.model.Search.SearchDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.Search.SearchDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Search.SearchDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    entitytype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lookup: jspb.Message.getFieldWithDefault(msg, 2, ""),
    strValue: (f = msg.getStrValue()) && proto.model.Search.StringSearch.toObject(includeInstance, f),
    intValue: (f = msg.getIntValue()) && proto.model.Search.IntegerSearch.toObject(includeInstance, f),
    boolValue: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    strListValue: (f = msg.getStrListValue()) && proto.model.Search.StringListSearch.toObject(includeInstance, f),
    timestampValue: (f = msg.getTimestampValue()) && proto.model.Search.TimestampSearch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.Search.SearchDetail}
 */
proto.model.Search.SearchDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.Search.SearchDetail;
  return proto.model.Search.SearchDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.Search.SearchDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.Search.SearchDetail}
 */
proto.model.Search.SearchDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntitytype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLookup(value);
      break;
    case 3:
      var value = new proto.model.Search.StringSearch;
      reader.readMessage(value,proto.model.Search.StringSearch.deserializeBinaryFromReader);
      msg.setStrValue(value);
      break;
    case 4:
      var value = new proto.model.Search.IntegerSearch;
      reader.readMessage(value,proto.model.Search.IntegerSearch.deserializeBinaryFromReader);
      msg.setIntValue(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolValue(value);
      break;
    case 6:
      var value = new proto.model.Search.StringListSearch;
      reader.readMessage(value,proto.model.Search.StringListSearch.deserializeBinaryFromReader);
      msg.setStrListValue(value);
      break;
    case 7:
      var value = new proto.model.Search.TimestampSearch;
      reader.readMessage(value,proto.model.Search.TimestampSearch.deserializeBinaryFromReader);
      msg.setTimestampValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.Search.SearchDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.Search.SearchDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.Search.SearchDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Search.SearchDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntitytype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLookup();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStrValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.model.Search.StringSearch.serializeBinaryToWriter
    );
  }
  f = message.getIntValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.model.Search.IntegerSearch.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getStrListValue();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.model.Search.StringListSearch.serializeBinaryToWriter
    );
  }
  f = message.getTimestampValue();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.model.Search.TimestampSearch.serializeBinaryToWriter
    );
  }
};


/**
 * optional string entityType = 1;
 * @return {string}
 */
proto.model.Search.SearchDetail.prototype.getEntitytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Search.SearchDetail} returns this
 */
proto.model.Search.SearchDetail.prototype.setEntitytype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string lookup = 2;
 * @return {string}
 */
proto.model.Search.SearchDetail.prototype.getLookup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Search.SearchDetail} returns this
 */
proto.model.Search.SearchDetail.prototype.setLookup = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional StringSearch str_value = 3;
 * @return {?proto.model.Search.StringSearch}
 */
proto.model.Search.SearchDetail.prototype.getStrValue = function() {
  return /** @type{?proto.model.Search.StringSearch} */ (
    jspb.Message.getWrapperField(this, proto.model.Search.StringSearch, 3));
};


/**
 * @param {?proto.model.Search.StringSearch|undefined} value
 * @return {!proto.model.Search.SearchDetail} returns this
*/
proto.model.Search.SearchDetail.prototype.setStrValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.model.Search.SearchDetail.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Search.SearchDetail} returns this
 */
proto.model.Search.SearchDetail.prototype.clearStrValue = function() {
  return this.setStrValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Search.SearchDetail.prototype.hasStrValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional IntegerSearch int_value = 4;
 * @return {?proto.model.Search.IntegerSearch}
 */
proto.model.Search.SearchDetail.prototype.getIntValue = function() {
  return /** @type{?proto.model.Search.IntegerSearch} */ (
    jspb.Message.getWrapperField(this, proto.model.Search.IntegerSearch, 4));
};


/**
 * @param {?proto.model.Search.IntegerSearch|undefined} value
 * @return {!proto.model.Search.SearchDetail} returns this
*/
proto.model.Search.SearchDetail.prototype.setIntValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.model.Search.SearchDetail.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Search.SearchDetail} returns this
 */
proto.model.Search.SearchDetail.prototype.clearIntValue = function() {
  return this.setIntValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Search.SearchDetail.prototype.hasIntValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool bool_value = 5;
 * @return {boolean}
 */
proto.model.Search.SearchDetail.prototype.getBoolValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Search.SearchDetail} returns this
 */
proto.model.Search.SearchDetail.prototype.setBoolValue = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.model.Search.SearchDetail.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.model.Search.SearchDetail} returns this
 */
proto.model.Search.SearchDetail.prototype.clearBoolValue = function() {
  return jspb.Message.setOneofField(this, 5, proto.model.Search.SearchDetail.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Search.SearchDetail.prototype.hasBoolValue = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional StringListSearch str_list_value = 6;
 * @return {?proto.model.Search.StringListSearch}
 */
proto.model.Search.SearchDetail.prototype.getStrListValue = function() {
  return /** @type{?proto.model.Search.StringListSearch} */ (
    jspb.Message.getWrapperField(this, proto.model.Search.StringListSearch, 6));
};


/**
 * @param {?proto.model.Search.StringListSearch|undefined} value
 * @return {!proto.model.Search.SearchDetail} returns this
*/
proto.model.Search.SearchDetail.prototype.setStrListValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.model.Search.SearchDetail.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Search.SearchDetail} returns this
 */
proto.model.Search.SearchDetail.prototype.clearStrListValue = function() {
  return this.setStrListValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Search.SearchDetail.prototype.hasStrListValue = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional TimestampSearch timestamp_value = 7;
 * @return {?proto.model.Search.TimestampSearch}
 */
proto.model.Search.SearchDetail.prototype.getTimestampValue = function() {
  return /** @type{?proto.model.Search.TimestampSearch} */ (
    jspb.Message.getWrapperField(this, proto.model.Search.TimestampSearch, 7));
};


/**
 * @param {?proto.model.Search.TimestampSearch|undefined} value
 * @return {!proto.model.Search.SearchDetail} returns this
*/
proto.model.Search.SearchDetail.prototype.setTimestampValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.model.Search.SearchDetail.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Search.SearchDetail} returns this
 */
proto.model.Search.SearchDetail.prototype.clearTimestampValue = function() {
  return this.setTimestampValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Search.SearchDetail.prototype.hasTimestampValue = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated SearchDetail search = 1;
 * @return {!Array<!proto.model.Search.SearchDetail>}
 */
proto.model.Search.prototype.getSearchList = function() {
  return /** @type{!Array<!proto.model.Search.SearchDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.Search.SearchDetail, 1));
};


/**
 * @param {!Array<!proto.model.Search.SearchDetail>} value
 * @return {!proto.model.Search} returns this
*/
proto.model.Search.prototype.setSearchList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.model.Search.SearchDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.Search.SearchDetail}
 */
proto.model.Search.prototype.addSearch = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.model.Search.SearchDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.Search} returns this
 */
proto.model.Search.prototype.clearSearchList = function() {
  return this.setSearchList([]);
};


goog.object.extend(exports, proto.model);
