import { h, Fragment } from 'preact'
import { useState } from 'preact/hooks'

import { Accordion, Grid, Icon, Transition } from 'semantic-ui-react'

export default function Orders({ orders }) {
    const [activeIndex, setActiveIndex] = useState(0)

    const handleClick = (e, titleProps) => {
        const { index } = titleProps
        const newIndex = activeIndex === index ? -1 : index

        setActiveIndex(newIndex)
    }

    const renderStatus = (status) => {
        const contact = status.getContact().toObject()
        return (
            <>
                <span>{` – ${status.getStatus()} `}</span>
                {contact && <span>({` ${contact.firstname} ${contact.lastname}`})</span>}
            </>
        )
    }

    return (
        <Accordion styled fluid>
            <Accordion.Title active={activeIndex === 0} index={0} onClick={handleClick}>
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Icon name="dropdown" /> Bestellungen
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
                <Transition visible={activeIndex === 0} animation="fade down" duration={500}>
                    <Grid style={{ maxHeight: "50vh", overflowY: "scroll" }}>
                        {orders.map((order) => (
                            <>
                                <Grid.Row columns={2} >
                                    <Grid.Column>
                                        <strong>
                                            <a
                                                style="text-decoration: none;"
                                                href={`${process.env.PREACT_APP_EXCELLENCE_BE_URI
                                                    }index.php?nav_id=53&id=${order.getTaskId()}`}
                                                target="_blank"
                                            >
                                                Aufgabe #{order.getTaskId()}
                                            </a>
                                        </strong>
                                        {renderStatus(order.getStatus())}
                                    </Grid.Column>
                                    <Grid.Column textAlign="right">
                                        {order.getTimestamp().toDate().toLocaleString('de-DE')}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <span>{`Bestellung #${order.getId()}`}</span>
                                        <br />
                                        <span>von</span>{' '}
                                        <a
                                            style="text-decoration: none;"
                                            href={`${process.env.PREACT_APP_EXCELLENCE_BE_URI
                                                }index.php?nav_id=50&kontakt_id=${order.getContact().getId()}`}
                                            target="_blank"
                                        >
                                            {order.getContact().getFirstname()} {order.getContact().getLastname()}
                                        </a>
                                    </Grid.Column>
                                    <Grid.Column textAlign="right">
                                        <span>{`${order.getPrice().toFixed(2)} EUR`}</span>
                                    </Grid.Column>
                                </Grid.Row>
                            </>
                        ))}
                    </Grid>
                </Transition>
            </Accordion.Content>
        </Accordion >
    )
}
