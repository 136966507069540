import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import { TransitionablePortal, Segment } from 'semantic-ui-react'

export default function Toast({ open, onClose, message, type }) {
    const color = type === 'success' ? 'green' : type === 'failed' ? 'red' : 'blue'

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                onClose()
            }, 3000)
        }
    }, [open])

    return (
        <TransitionablePortal
            onClose={onClose}
            open={open}
            transition={{ animation: 'fly left', duration: 600 }}
            mountNode={document.getElementById('content')}
        >
            <Segment
                color={color}
                padded
                style={{
                    position: 'fixed',
                    right: '0',
                    top: '25px',
                    zIndex: 1050,
                }}
            >
                {message}
            </Segment>
        </TransitionablePortal>
    )
}
