import { Component, h, Fragment, createRef } from 'preact'

import { Grid, Pagination, Dropdown, Button, Input, Menu, Sticky, Ref, Container, Icon, Table } from 'semantic-ui-react'

import * as listElements from '../components/resultlistelement.js'

import { Text, translate } from 'preact-i18n'

import translation from '../assets/i18n/de_DE.json'

import {
    store,
    setActiveResultPage,
    setElementsPerPage,
    setResultSearchAttribute,
    setVisibility,
    setLayout,
} from '../redux/store.js'

const searchOptions = {
    company: [
        { key: 'name', text: <Text id="attributes.company.name">Name</Text>, value: 'name' },
        { key: 'edvnr', text: <Text id="attributes.company.edvnr">EdvNr</Text>, value: 'edvnr' },
        { key: 'mccode', text: <Text id="attributes.company.mccode">Match Code</Text>, value: 'mccode' },
        {
            key: 'nameextend',
            text: <Text id="attributes.company.nameextend">Name Extension</Text>,
            value: 'nameextend',
        },
        { key: 'city', text: <Text id="attributes.company.city">City</Text>, value: 'city' },
        { key: 'street', text: <Text id="attributes.company.street">Street</Text>, value: 'street' },
        { key: 'mail', text: <Text id="attributes.company.mail">Mail Address</Text>, value: 'mail' },
    ],
    contact: [
        { key: 'firstname', text: <Text id="attributes.contact.firstname">Firstname</Text>, value: 'firstname' },
        { key: 'lastname', text: <Text id="attributes.contact.lastname">Lastname</Text>, value: 'lastname' },
        { key: 'mail', text: <Text id="attributes.contact.mail">Lastname</Text>, value: 'mail' },
    ],
}

class ResultSet extends Component {
    ctxRef = createRef()

    constructor(props) {
        super(props)

        this.state = {
            resultsPerPage: store.getState().filterResult.elementsPerPage,
            activePage: store.getState().filterResult.activePage || 1,
            totalPages: 1,
            layout: store.getState().layout,
            selected: props.selected,
            searchedResultSet: false,
            searchResultValue: '',
            resultsPerPageOptions: [
                { key: 5, text: 5, value: 5 },
                { key: 10, text: 10, value: 10 },
                { key: 25, text: 25, value: 25 },
                { key: 50, text: 50, value: 50 },
                { key: 100, text: 100, value: 100 },
            ],
        }

        this.resultset = []
        store.subscribe(() => {
            this.setState({
                activePage: store.getState().filterResult.activePage,
                resultsPerPage: store.getState().filterResult.elementsPerPage,
            })
        })
    }

    chunkArray = (arr, size) => {
        return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size))
    }

    searchData = (where, what) => {
        if (where == false || what == '') {
            this.resultset = this.chunkArray(this.props.data, this.state.resultsPerPage)
            this.setState({
                searchResultValue: '',
                searchedResultSet: true,
            })
            return
        }

        const whatLower = what.toLowerCase()

        let entityType = null
        let valueGetter = null
        this.resultset = this.chunkArray(
            this.props.data.filter((ele) => {
                if (entityType === null) {
                    entityType = ele.array[0].charAt(0).toUpperCase() + ele.array[0].slice(1)
                }
                if (valueGetter === null) {
                    valueGetter = 'get' + where.charAt(0).toUpperCase() + where.slice(1)
                }
                const entity = ele['get' + entityType]()
                return entity[valueGetter]().toLowerCase().includes(whatLower)
            }),
            this.state.resultsPerPage
        )

        this.setState({
            activePage: 1,
            searchResultValue: what,
            searchedResultSet: true,
        })
    }

    handleAddition = (e, { value }) => {
        this.setState((prevState) => ({
            resultsPerPageOptions: [{ text: value, value }, ...prevState.resultsPerPageOptions],
        }))
    }

    handleChange = (e, { value }) => {
        this.setState((state) => {
            state.resultsPerPage = value
            state.searchedResultSet = false
        })

        store.dispatch(setElementsPerPage(value))

        // jump to page of first element on previous page
        if (this.resultset[this.state.activePage - 1]) {
            let current = this.resultset[this.state.activePage - 1][0]
            const type = current.getResponsetype()
            const objId = current.toObject()[type].id
            let currentFirstIndex = this.props.data.findIndex((ele) => ele.toObject()[type].id === objId)

            // Calculate Offset
            this.setState({
                activePage: Math.floor(currentFirstIndex / value + 1),
            })
        }
    }

    handlePageChange = (ev, data) => {
        this.setState({
            activePage: data.activePage,
            totalPages: this.resultset.length,
        })

        store.dispatch(setActiveResultPage(data.activePage))
    }

    getTotal = () => {
        const total = {
            companies: 0,
            contacts: 0,
        }

        this.state.data.map((chunk, idx) => {
            chunk.forEach((ele, idx) => {
                total.companies++
                total.contacts += ele.getContactList().length
            })
        })

        return total
    }

    shouldComponentUpdate(nextProps, nextState) {
        // Clear
        if (nextState.searchedResultSet) {
            return true
        }

        if (nextProps.data.length === 0) {
            this.resultset = []
            this.setState({
                totalPages: 0,
            })
        }

        // New resultset
        if (nextProps.data.length != this.resultset.length) {
            this.resultset = this.chunkArray(nextProps.data, nextState.resultsPerPage)
        }

        return true
    }

    componentDidMount() {
        if (this.resultset.length != this.props.data.length) {
            this.resultset = this.chunkArray(this.props.data, this.state.resultsPerPage)

            this.setState({
                totalPages: this.resultset.length,
            })
        }
    }

    setActivePage = (num) => {
        this.setState({
            activePage: store.getState().filterResult.activePage,
        })
        store.dispatch(setActiveResultPage(Number.parseInt(num)))
    }

    setLayout = (type) => {
        store.dispatch(setLayout(type))
    }

    render(props, state) {
        const responseType = (props.data[0] && props.data[0].getResponsetype()) || 'company'

        return (
            <>
                <Ref innerRef={this.ctxRef}>
                    <Container fluid>
                        <div
                            style={{
                                backgroundColor: 'white',
                                width: '100%',
                                height: '22px',
                                position: 'fixed',
                                top: '64px',
                                zIndex: 9,
                            }}
                        />
                        <Sticky
                            styleElement={{ backgroundColor: 'white', paddingBottom: '26px' }}
                            context={this.ctxRef}
                            offset={86}
                        >
                            <Menu secondary fluid stackable>
                                <Menu.Item>
                                    <Button basic onClick={() => store.dispatch(setVisibility('search'))}>
                                        <Text
                                            id="back_to"
                                            fields={{ what: translate('searchcriteria', '', translation) }}
                                        >
                                            back to search
                                        </Text>
                                    </Button>
                                </Menu.Item>
                                <Menu.Item>
                                    <Button basic icon onClick={() => this.setLayout('table')}>
                                        <Icon name="table" />
                                    </Button>
                                    <Button basic icon onClick={() => this.setLayout('grid')}>
                                        <Icon name="grid layout" />
                                    </Button>
                                </Menu.Item>
                                <Menu.Item>
                                    <span>
                                        <Text id="results_per_page" />{' '}
                                        <Dropdown
                                            inline
                                            defaultValue={state.resultsPerPage}
                                            options={state.resultsPerPageOptions}
                                            onChange={this.handleChange}
                                        />
                                    </span>
                                </Menu.Item>
                                <Menu.Item>
                                    <span>
                                        <Input
                                            placeholder={translate('search_in', '', translation)}
                                            value={state.searchResultValue}
                                            onChange={(ev, data) =>
                                                this.searchData(
                                                    store.getState().filterResult.resultSearchAttribute,
                                                    data.value
                                                )
                                            }
                                        />
                                        &nbsp;
                                        <Dropdown
                                            inline
                                            options={searchOptions[responseType]}
                                            onChange={(ev, data) => {
                                                this.searchData(null, '')
                                                store.dispatch(setResultSearchAttribute(data.value))
                                            }}
                                        />
                                    </span>
                                </Menu.Item>
                            </Menu>
                        </Sticky>
                        {store.getState().layout === 'grid' && (
                            <Grid columns={5} divided>
                                {this.resultset[state.activePage - 1] &&
                                    this.resultset[state.activePage - 1].map((ele, idx) =>
                                        listElements[responseType](ele, store.getState().layout)
                                    )}
                            </Grid>
                        )}
                        {store.getState().layout === 'table' && (
                            <Table celled fixed selectable structured striped className="fixed-table-header">
                                {responseType && listElements[responseType + 'TableHeader']()}
                                <Table.Body>
                                    {this.resultset[state.activePage - 1] &&
                                        this.resultset[state.activePage - 1].map((ele, idx) =>
                                            listElements[responseType](ele, store.getState().layout)
                                        )}
                                </Table.Body>
                            </Table>
                        )}
                    </Container>
                </Ref>
                <Menu secondary fluid>
                    <Menu.Item>
                        <Pagination
                            fluid
                            totalPages={this.resultset.length}
                            activePage={state.activePage}
                            onPageChange={this.handlePageChange}
                            secondary
                        />
                    </Menu.Item>
                    <Menu.Item>
                        <Input
                            label={translate('goto', '', translation)}
                            value={state.activePage}
                            type="number"
                            step="1"
                            min="1"
                            max={state.totalPages}
                            onChange={(ev, data) => this.setActivePage(data.value)}
                        />
                    </Menu.Item>
                </Menu>
            </>
        )
    }
}

export default ResultSet
