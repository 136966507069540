import { Component, h } from 'preact'
import { connect } from 'react-redux'
import { store, fetchData, setCompanySelection, setContactSelection } from '../redux/store.js'

import { AdmDetails } from './details/adm.js'

import { route } from 'preact-router'

import { Button, Container, Grid, Card, Table } from 'semantic-ui-react'

import { Text } from 'preact-i18n'
import CompanyDetails from './details/company.js'

const showEntity = (ev, ele) => {
    let obj = null
    // Scroll top into view
    switch (ele.datatype) {
        case 'company':
            obj = ele.company
            store.dispatch(setCompanySelection(obj))
            break
        case 'contact':
            store.dispatch(setContactSelection(ele.contact))
            obj = ele.contact
            break
    }

    route('/backend/v2/mitgliedersuche/' + ele.datatype + '/' + obj.getId())
}

const contactDetails = (props) => {
    if (props.contact === null) {
        return
    }

    const companyLink = []
    props.contact.getCompaniesList().map((company, idx) => {
        companyLink[companyLink.length] = (
            <Button datatype="company" company={company} onClick={showEntity}>
                {company.getName()}
            </Button>
        )
    })

    return (
        <div>
            <span>
                {props.contact.getFirstname()} {props.contact.getLastname()}
            </span>
            {companyLink}
        </div>
    )
}

const propertyRender = {
    createdAt: (obj, ts) => obj.getCreatedAt().toDate().toLocaleString('de-DE'),
    updatedAt: (obj, ts) => obj.getUpdatedAt().toDate().toLocaleString('de-DE'),
    segmentation1: (obj, val) => obj.getSegmentation1().toFixed(2),
    segmentation2: (obj, val) => obj.getSegmentation2().toFixed(2),
    contactList: (obj, val) => console.dir(obj.getContactList()),
}

const companyDetails = (props, state) => {
    if (props.company === null) {
        return
    }

    if (!state.loaded) {
        store.dispatch(fetchData({ type: 'company_contacts' }))
        state.loaded = true
    }

    const companyData = []
    const companyAsObj = props.company.toObject()
    for (let prop in companyAsObj) {
        let val = companyAsObj[prop]
        if (propertyRender[prop]) {
            val = propertyRender[prop](props.company, prop)
        }

        companyData[companyData.length] = (
            <Table.Row>
                <Table.Cell>
                    <Text id={`attributes.company.${prop}`}>{prop}</Text>
                </Table.Cell>
                <Table.Cell>{val}</Table.Cell>
            </Table.Row>
        )
    }

    return (
        <Container fluid>
            <Grid>
                <Grid.Column>
                    <Card fluid>
                        <Card.Content>
                            <Card.Header>{props.company.getName()}</Card.Header>
                        </Card.Content>
                        <Card.Content>
                            <Table>{companyData}</Table>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid>
        </Container>
    )
}

const detailSwitch = (props, state) => {
    switch (props.entityType) {
        case 'company':
            // return companyDetails(props, state)
            return <CompanyDetails grpcclient={props.grpcclient} company={props.company} />
        case 'contact':
            return contactDetails(props, state)
        case 'adm':
            return AdmDetails(props, state)
    }
}

export class Details extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
        }
    }

    componentDidMount() {
        if (this.props[this.props.entityType] === null) {
            store.dispatch(fetchData({ type: this.props.entityType, id: this.props.id }))
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps[nextProps.entityType] === null) {
            return false
        }
        return true
    }

    render(props, state) {
        return <div>{props.entityType && detailSwitch(props, state)}</div>
    }
}

export default connect((state) => ({
    company: state.company.selectedCompany,
    contact: state.contact.selectedContact,
}))(Details)
