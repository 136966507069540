import { h } from 'preact'
import { Text } from 'preact-i18n'
import { useEffect, useState } from 'preact/hooks'
import { useSelector } from 'react-redux'

import { Accordion, Card, Container, Dimmer, Divider, Grid, Icon, Loader, Table, Transition } from 'semantic-ui-react'

import { detailPropertyRender } from '../helpers'
import Toast from '../portals/Toast'
import Orders from './orders'

export default function CompanyDetails({ grpcclient, company }) {
    const accessToken = useSelector((state) => state.accesstoken)

    const [comp, setComp] = useState(company)
    const [activeIndex, setActiveIndex] = useState(0)
    const [toast, setToast] = useState({ open: false, message: '' })

    useEffect(() => {
        if (company && accessToken) {
            grpcclient.getOrdersForCompany(
                company,
                {
                    'x-auth-ispf': accessToken,
                },
                (errCode, response) => {
                    if (errCode) {
                        setToast({
                            open: true,
                            message: (
                                <Text id="toast.loadOrdersForCompany.failed">
                                    Beim Laden der Bestellungen ist ein Fehler aufgetreten!
                                </Text>
                            ),
                            type: 'failed',
                        })
                    } else {
                        if (response) {
                            setComp(response)
                        }
                    }
                }
            )
        }
    }, [grpcclient, accessToken, company])

    if (!company || !comp) {
        return <Loader active />
    }

    const handleClick = (e, titleProps) => {
        const { index } = titleProps
        const newIndex = activeIndex === index ? -1 : index

        setActiveIndex(newIndex)
    }

    const companyData = []
    const companyAsObj = comp.toObject()

    for (let prop in companyAsObj) {
        let val = companyAsObj[prop]
        if (detailPropertyRender[prop]) {
            val = detailPropertyRender[prop](comp, prop)
        }

        if (Array.isArray(val)) {
        } else {
            companyData.push(
                <Table.Row>
                    <Table.Cell>
                        <Text id={`attributes.company.${prop}`}>{prop}</Text>
                    </Table.Cell>
                    <Table.Cell>{val}</Table.Cell>
                </Table.Row>
            )
        }
    }

    return (
        <Container fluid>
            <Grid>
                <Grid.Column>
                    <Card fluid>
                        <Card.Content>
                            <Card.Header>{company.getName()}</Card.Header>
                        </Card.Content>
                        <Card.Content>
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <Accordion styled fluid>
                                            <Accordion.Title active={activeIndex === 0} index={0} onClick={handleClick}>
                                                <Grid>
                                                    <Grid.Row columns={1}>
                                                        <Grid.Column>
                                                            <Icon name="dropdown" /> Firma
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Accordion.Title>
                                            <Accordion.Content active={activeIndex === 0}>
                                                <Transition
                                                    visible={activeIndex === 0}
                                                    animation="fade down"
                                                    duration={500}
                                                >
                                                    <Grid>
                                                        <Grid.Row>
                                                            <Grid.Column>{comp.getName()}</Grid.Column>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <span>{comp.getStreet()}</span>
                                                                <br />
                                                                <span>{comp.getZipcode()}</span>
                                                                <br />
                                                                <span>{comp.getCity()}</span>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        <Divider />
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <Text id={`attributes.company.edvnr`}>edvnr</Text>
                                                                {': '}
                                                                {comp.getEdvnr()}
                                                                <br />
                                                                <Text id={`attributes.company.mccode`}>mccode</Text>
                                                                {': '}
                                                                {comp.getMccode()}
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Transition>
                                            </Accordion.Content>
                                        </Accordion>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Orders orders={comp.getOrdersList()} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Card.Content>
                        <Card.Content>
                            <Table>{companyData}</Table>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid>
            <Toast
                open={toast.open}
                onClose={() => setToast({ ...toast, open: false })}
                message={toast.message}
                type={toast.type}
            />
        </Container>
    )
}
