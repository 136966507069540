import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { connect, useSelector } from 'react-redux'

import { Empty } from 'google-protobuf/google/protobuf/empty_pb'

import { Container, Grid, Ref, Dropdown, Button, TransitionablePortal, Segment, Header } from 'semantic-ui-react'
import SaveFiltermaskDialog from '../dialogs/saveFiltermaskDialog'
import { Text } from 'preact-i18n'
import DelFiltermaskDialog from '../dialogs/delFiltermaskDialog'
import Toast from '../portals/Toast'

export default function UserFilters({ grpcclient, currentSearch, setSearchByMask }) {
    const accessToken = useSelector((state) => state.accesstoken)

    const [loading, setLoading] = useState(true)
    const [userFilter, setUserFilter] = useState([])
    const [currentFilter, setCurrentFilter] = useState(null)
    const [updateFilter, setUpdateFilter] = useState(true)
    const [open, setOpen] = useState(false)
    const [openDel, setOpenDel] = useState(false)
    const [toast, setToast] = useState({ open: false, message: '' })

    useEffect(() => {
        if (!accessToken) {
            setLoading(true)
        }
        if (accessToken && updateFilter) {
            window.token = accessToken
            setLoading(true)
            grpcclient.getFilterByUserName(
                new Empty(),
                {
                    'x-auth-ispf': accessToken,
                },
                (errCode, response) => {
                    if (errCode) {
                        setToast({
                            open: true,
                            message: (
                                <Text id="toast.loadUserFilter.failed">
                                    Beim Laden der Filtermasken ist ein Fehler aufgetreten!
                                </Text>
                            ),
                            type: 'failed',
                        })
                    } else {
                        if (response) {
                            const userFilters = response.getFilterList()
                            setUserFilter(
                                userFilters.map((el) => ({
                                    key: el.getName(),
                                    text: el.getName(),
                                    value: el,
                                }))
                            )
                        }
                    }
                    setLoading(false)
                    setUpdateFilter(false)
                }
            )
        }
    }, [accessToken, updateFilter])

    const saveUserFilter = (name) => {
        const filtermask = new proto.Filtermask()
        filtermask.setName(name)
        filtermask.setFilter(currentSearch)

        grpcclient.setFilterByUserName(
            filtermask,
            {
                'x-auth-ispf': accessToken,
            },
            (e, r) => {
                if (e) {
                    setToast({
                        open: true,
                        message: <Text id="toast.saveUserFilter.failed">Beim Anlegen ist ein Fehler aufgetreten!</Text>,
                        type: 'failed',
                    })
                } else {
                    if (r) {
                        setCurrentFilter(filtermask)
                        setUpdateFilter(true)
                        setToast({
                            open: true,
                            message: <Text id="toast.saveUserFilter.success">Filtermaske erstellt!</Text>,
                            type: 'success',
                        })
                    }
                }
            }
        )
    }

    const deleteUserFilter = () => {
        grpcclient.removeFilter(
            currentFilter,
            {
                'x-auth-ispf': accessToken,
            },
            (e, r) => {
                if (e) {
                    setToast({
                        open: true,
                        message: <Text id="toast.delUserFilter.failed">Beim Löschen ist ein Fehler aufgetreten!</Text>,
                        type: 'failed',
                    })
                } else {
                    if (r) {
                        clearSearch()
                        setUpdateFilter(true)
                        setToast({
                            open: true,
                            message: <Text id="toast.delUserFilter.success">Filtermaske gelöscht!</Text>,
                            type: 'success',
                        })
                    }
                }
            }
        )
    }

    const handleChange = (e, { value }) => {
        if (value !== '') {
            setSearchByMask(value)
            setCurrentFilter(value)
        } else {
            // clear search
            clearSearch()
        }
    }

    const clearSearch = () => {
        const filter = new proto.Filtermask()
        const search = new proto.Search()
        filter.setFilter(search)

        setSearchByMask(filter)
        setCurrentFilter(null)
    }

    const handleClick = (e) => {
        const element = dropdownItem.current.querySelector('[aria-selected="true"]')
        if (element) {
            dropdownItem.current.querySelector('.clear').click()
        }
    }

    return (
        <Grid.Row columns={2}>
            <Grid.Column>
                <Dropdown
                    placeholder={<Text id="userFilters.dropdown.placeholder">Filtermasken</Text>}
                    search
                    selection
                    clearable
                    loading={loading}
                    options={userFilter}
                    value={currentFilter}
                    onChange={handleChange}
                />
                <Button size="mini" icon="add" onClick={() => setOpen(true)} />
                <Button
                    size="mini"
                    icon="trash alternate outline"
                    disabled={!currentFilter}
                    onClick={() => setOpenDel(true)}
                />
            </Grid.Column>
            <SaveFiltermaskDialog
                open={open}
                setOpen={setOpen}
                currentFilter={currentFilter}
                saveUserFilter={saveUserFilter}
            />
            <DelFiltermaskDialog open={openDel} setOpen={setOpenDel} removeFilter={deleteUserFilter} />
            <Toast
                open={toast.open}
                onClose={() => setToast({ ...toast, open: false })}
                message={toast.message}
                type={toast.type}
            />
        </Grid.Row>
    )
}
