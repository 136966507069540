/**
 * @fileoverview gRPC-Web generated client stub for service.usermanagement
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js')

var model_search_pb = require('../model/search_pb.js')

var model_crm_pb = require('../model/crm_pb.js')

var model_listvalueresolver_pb = require('../model/listvalueresolver_pb.js')

var model_structure_pb = require('../model/structure_pb.js')

var model_filtermask_pb = require('../model/filtermask_pb.js')
const proto = {};
proto.service = {};
proto.service.usermanagement = require('./grpc_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.service.usermanagement.CrmClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.service.usermanagement.CrmPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.Search,
 *   !proto.model.SearchResponse>}
 */
const methodDescriptor_Crm_Search = new grpc.web.MethodDescriptor(
  '/service.usermanagement.Crm/Search',
  grpc.web.MethodType.SERVER_STREAMING,
  model_search_pb.Search,
  model_search_pb.SearchResponse,
  /**
   * @param {!proto.model.Search} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_search_pb.SearchResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.model.Search,
 *   !proto.model.SearchResponse>}
 */
const methodInfo_Crm_Search = new grpc.web.AbstractClientBase.MethodInfo(
  model_search_pb.SearchResponse,
  /**
   * @param {!proto.model.Search} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_search_pb.SearchResponse.deserializeBinary
);


/**
 * @param {!proto.model.Search} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.model.SearchResponse>}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmClient.prototype.search =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/service.usermanagement.Crm/Search',
      request,
      metadata || {},
      methodDescriptor_Crm_Search);
};


/**
 * @param {!proto.model.Search} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.model.SearchResponse>}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmPromiseClient.prototype.search =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/service.usermanagement.Crm/Search',
      request,
      metadata || {},
      methodDescriptor_Crm_Search);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ListValueResolver,
 *   !proto.google.protobuf.ListValue>}
 */
const methodDescriptor_Crm_ResolveListValues = new grpc.web.MethodDescriptor(
  '/service.usermanagement.Crm/ResolveListValues',
  grpc.web.MethodType.UNARY,
  model_listvalueresolver_pb.ListValueResolver,
  google_protobuf_struct_pb.ListValue,
  /**
   * @param {!proto.model.ListValueResolver} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_struct_pb.ListValue.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.model.ListValueResolver,
 *   !proto.google.protobuf.ListValue>}
 */
const methodInfo_Crm_ResolveListValues = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_struct_pb.ListValue,
  /**
   * @param {!proto.model.ListValueResolver} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_struct_pb.ListValue.deserializeBinary
);


/**
 * @param {!proto.model.ListValueResolver} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.ListValue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.ListValue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmClient.prototype.resolveListValues =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.usermanagement.Crm/ResolveListValues',
      request,
      metadata || {},
      methodDescriptor_Crm_ResolveListValues,
      callback);
};


/**
 * @param {!proto.model.ListValueResolver} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.ListValue>}
 *     Promise that resolves to the response
 */
proto.service.usermanagement.CrmPromiseClient.prototype.resolveListValues =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.usermanagement.Crm/ResolveListValues',
      request,
      metadata || {},
      methodDescriptor_Crm_ResolveListValues);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.Company,
 *   !proto.model.Company>}
 */
const methodDescriptor_Crm_GetCompanyById = new grpc.web.MethodDescriptor(
  '/service.usermanagement.Crm/GetCompanyById',
  grpc.web.MethodType.UNARY,
  model_crm_pb.Company,
  model_crm_pb.Company,
  /**
   * @param {!proto.model.Company} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_crm_pb.Company.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.model.Company,
 *   !proto.model.Company>}
 */
const methodInfo_Crm_GetCompanyById = new grpc.web.AbstractClientBase.MethodInfo(
  model_crm_pb.Company,
  /**
   * @param {!proto.model.Company} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_crm_pb.Company.deserializeBinary
);


/**
 * @param {!proto.model.Company} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.model.Company)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Company>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmClient.prototype.getCompanyById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.usermanagement.Crm/GetCompanyById',
      request,
      metadata || {},
      methodDescriptor_Crm_GetCompanyById,
      callback);
};


/**
 * @param {!proto.model.Company} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Company>}
 *     Promise that resolves to the response
 */
proto.service.usermanagement.CrmPromiseClient.prototype.getCompanyById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.usermanagement.Crm/GetCompanyById',
      request,
      metadata || {},
      methodDescriptor_Crm_GetCompanyById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.Contact,
 *   !proto.model.Contact>}
 */
const methodDescriptor_Crm_GetContactById = new grpc.web.MethodDescriptor(
  '/service.usermanagement.Crm/GetContactById',
  grpc.web.MethodType.UNARY,
  model_crm_pb.Contact,
  model_crm_pb.Contact,
  /**
   * @param {!proto.model.Contact} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_crm_pb.Contact.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.model.Contact,
 *   !proto.model.Contact>}
 */
const methodInfo_Crm_GetContactById = new grpc.web.AbstractClientBase.MethodInfo(
  model_crm_pb.Contact,
  /**
   * @param {!proto.model.Contact} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_crm_pb.Contact.deserializeBinary
);


/**
 * @param {!proto.model.Contact} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.model.Contact)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Contact>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmClient.prototype.getContactById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.usermanagement.Crm/GetContactById',
      request,
      metadata || {},
      methodDescriptor_Crm_GetContactById,
      callback);
};


/**
 * @param {!proto.model.Contact} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Contact>}
 *     Promise that resolves to the response
 */
proto.service.usermanagement.CrmPromiseClient.prototype.getContactById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.usermanagement.Crm/GetContactById',
      request,
      metadata || {},
      methodDescriptor_Crm_GetContactById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.Company,
 *   !proto.model.Company>}
 */
const methodDescriptor_Crm_GetContactsForCompany = new grpc.web.MethodDescriptor(
  '/service.usermanagement.Crm/GetContactsForCompany',
  grpc.web.MethodType.UNARY,
  model_crm_pb.Company,
  model_crm_pb.Company,
  /**
   * @param {!proto.model.Company} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_crm_pb.Company.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.model.Company,
 *   !proto.model.Company>}
 */
const methodInfo_Crm_GetContactsForCompany = new grpc.web.AbstractClientBase.MethodInfo(
  model_crm_pb.Company,
  /**
   * @param {!proto.model.Company} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_crm_pb.Company.deserializeBinary
);


/**
 * @param {!proto.model.Company} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.model.Company)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Company>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmClient.prototype.getContactsForCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.usermanagement.Crm/GetContactsForCompany',
      request,
      metadata || {},
      methodDescriptor_Crm_GetContactsForCompany,
      callback);
};


/**
 * @param {!proto.model.Company} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Company>}
 *     Promise that resolves to the response
 */
proto.service.usermanagement.CrmPromiseClient.prototype.getContactsForCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.usermanagement.Crm/GetContactsForCompany',
      request,
      metadata || {},
      methodDescriptor_Crm_GetContactsForCompany);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.model.Adm>}
 */
const methodDescriptor_Crm_GetADMS = new grpc.web.MethodDescriptor(
  '/service.usermanagement.Crm/GetADMS',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  model_crm_pb.Adm,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_crm_pb.Adm.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.model.Adm>}
 */
const methodInfo_Crm_GetADMS = new grpc.web.AbstractClientBase.MethodInfo(
  model_crm_pb.Adm,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_crm_pb.Adm.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.model.Adm>}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmClient.prototype.getADMS =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/service.usermanagement.Crm/GetADMS',
      request,
      metadata || {},
      methodDescriptor_Crm_GetADMS);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.model.Adm>}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmPromiseClient.prototype.getADMS =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/service.usermanagement.Crm/GetADMS',
      request,
      metadata || {},
      methodDescriptor_Crm_GetADMS);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.model.UserFilters>}
 */
const methodDescriptor_Crm_GetFilterByUserName = new grpc.web.MethodDescriptor(
  '/service.usermanagement.Crm/GetFilterByUserName',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  model_filtermask_pb.UserFilters,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_filtermask_pb.UserFilters.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.model.UserFilters>}
 */
const methodInfo_Crm_GetFilterByUserName = new grpc.web.AbstractClientBase.MethodInfo(
  model_filtermask_pb.UserFilters,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_filtermask_pb.UserFilters.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.model.UserFilters)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.UserFilters>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmClient.prototype.getFilterByUserName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.usermanagement.Crm/GetFilterByUserName',
      request,
      metadata || {},
      methodDescriptor_Crm_GetFilterByUserName,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.UserFilters>}
 *     Promise that resolves to the response
 */
proto.service.usermanagement.CrmPromiseClient.prototype.getFilterByUserName =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.usermanagement.Crm/GetFilterByUserName',
      request,
      metadata || {},
      methodDescriptor_Crm_GetFilterByUserName);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.Filtermask,
 *   !proto.google.protobuf.BoolValue>}
 */
const methodDescriptor_Crm_SetFilterByUserName = new grpc.web.MethodDescriptor(
  '/service.usermanagement.Crm/SetFilterByUserName',
  grpc.web.MethodType.UNARY,
  model_filtermask_pb.Filtermask,
  google_protobuf_wrappers_pb.BoolValue,
  /**
   * @param {!proto.model.Filtermask} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.BoolValue.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.model.Filtermask,
 *   !proto.google.protobuf.BoolValue>}
 */
const methodInfo_Crm_SetFilterByUserName = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_wrappers_pb.BoolValue,
  /**
   * @param {!proto.model.Filtermask} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.BoolValue.deserializeBinary
);


/**
 * @param {!proto.model.Filtermask} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.BoolValue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.BoolValue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmClient.prototype.setFilterByUserName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.usermanagement.Crm/SetFilterByUserName',
      request,
      metadata || {},
      methodDescriptor_Crm_SetFilterByUserName,
      callback);
};


/**
 * @param {!proto.model.Filtermask} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.BoolValue>}
 *     Promise that resolves to the response
 */
proto.service.usermanagement.CrmPromiseClient.prototype.setFilterByUserName =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.usermanagement.Crm/SetFilterByUserName',
      request,
      metadata || {},
      methodDescriptor_Crm_SetFilterByUserName);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.Filtermask,
 *   !proto.google.protobuf.BoolValue>}
 */
const methodDescriptor_Crm_RemoveFilter = new grpc.web.MethodDescriptor(
  '/service.usermanagement.Crm/RemoveFilter',
  grpc.web.MethodType.UNARY,
  model_filtermask_pb.Filtermask,
  google_protobuf_wrappers_pb.BoolValue,
  /**
   * @param {!proto.model.Filtermask} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.BoolValue.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.model.Filtermask,
 *   !proto.google.protobuf.BoolValue>}
 */
const methodInfo_Crm_RemoveFilter = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_wrappers_pb.BoolValue,
  /**
   * @param {!proto.model.Filtermask} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.BoolValue.deserializeBinary
);


/**
 * @param {!proto.model.Filtermask} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.BoolValue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.BoolValue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmClient.prototype.removeFilter =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.usermanagement.Crm/RemoveFilter',
      request,
      metadata || {},
      methodDescriptor_Crm_RemoveFilter,
      callback);
};


/**
 * @param {!proto.model.Filtermask} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.BoolValue>}
 *     Promise that resolves to the response
 */
proto.service.usermanagement.CrmPromiseClient.prototype.removeFilter =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.usermanagement.Crm/RemoveFilter',
      request,
      metadata || {},
      methodDescriptor_Crm_RemoveFilter);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.model.Adm>}
 */
const methodDescriptor_Crm_GetLegacyADMS = new grpc.web.MethodDescriptor(
  '/service.usermanagement.Crm/GetLegacyADMS',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  model_crm_pb.Adm,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_crm_pb.Adm.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.model.Adm>}
 */
const methodInfo_Crm_GetLegacyADMS = new grpc.web.AbstractClientBase.MethodInfo(
  model_crm_pb.Adm,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_crm_pb.Adm.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.model.Adm>}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmClient.prototype.getLegacyADMS =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/service.usermanagement.Crm/GetLegacyADMS',
      request,
      metadata || {},
      methodDescriptor_Crm_GetLegacyADMS);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.model.Adm>}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmPromiseClient.prototype.getLegacyADMS =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/service.usermanagement.Crm/GetLegacyADMS',
      request,
      metadata || {},
      methodDescriptor_Crm_GetLegacyADMS);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.model.Adm>}
 */
const methodDescriptor_Crm_GetLegacyStructures = new grpc.web.MethodDescriptor(
  '/service.usermanagement.Crm/GetLegacyStructures',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  model_crm_pb.Adm,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_crm_pb.Adm.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.model.Adm>}
 */
const methodInfo_Crm_GetLegacyStructures = new grpc.web.AbstractClientBase.MethodInfo(
  model_crm_pb.Adm,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_crm_pb.Adm.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.model.Adm>}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmClient.prototype.getLegacyStructures =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/service.usermanagement.Crm/GetLegacyStructures',
      request,
      metadata || {},
      methodDescriptor_Crm_GetLegacyStructures);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.model.Adm>}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmPromiseClient.prototype.getLegacyStructures =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/service.usermanagement.Crm/GetLegacyStructures',
      request,
      metadata || {},
      methodDescriptor_Crm_GetLegacyStructures);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.Structure,
 *   !proto.google.protobuf.BoolValue>}
 */
const methodDescriptor_Crm_CreateStructure = new grpc.web.MethodDescriptor(
  '/service.usermanagement.Crm/CreateStructure',
  grpc.web.MethodType.UNARY,
  model_structure_pb.Structure,
  google_protobuf_wrappers_pb.BoolValue,
  /**
   * @param {!proto.model.Structure} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.BoolValue.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.model.Structure,
 *   !proto.google.protobuf.BoolValue>}
 */
const methodInfo_Crm_CreateStructure = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_wrappers_pb.BoolValue,
  /**
   * @param {!proto.model.Structure} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.BoolValue.deserializeBinary
);


/**
 * @param {!proto.model.Structure} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.BoolValue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.BoolValue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmClient.prototype.createStructure =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.usermanagement.Crm/CreateStructure',
      request,
      metadata || {},
      methodDescriptor_Crm_CreateStructure,
      callback);
};


/**
 * @param {!proto.model.Structure} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.BoolValue>}
 *     Promise that resolves to the response
 */
proto.service.usermanagement.CrmPromiseClient.prototype.createStructure =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.usermanagement.Crm/CreateStructure',
      request,
      metadata || {},
      methodDescriptor_Crm_CreateStructure);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.Adm,
 *   !proto.google.protobuf.BoolValue>}
 */
const methodDescriptor_Crm_RemoveStructureADM = new grpc.web.MethodDescriptor(
  '/service.usermanagement.Crm/RemoveStructureADM',
  grpc.web.MethodType.UNARY,
  model_crm_pb.Adm,
  google_protobuf_wrappers_pb.BoolValue,
  /**
   * @param {!proto.model.Adm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.BoolValue.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.model.Adm,
 *   !proto.google.protobuf.BoolValue>}
 */
const methodInfo_Crm_RemoveStructureADM = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_wrappers_pb.BoolValue,
  /**
   * @param {!proto.model.Adm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.BoolValue.deserializeBinary
);


/**
 * @param {!proto.model.Adm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.BoolValue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.BoolValue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmClient.prototype.removeStructureADM =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.usermanagement.Crm/RemoveStructureADM',
      request,
      metadata || {},
      methodDescriptor_Crm_RemoveStructureADM,
      callback);
};


/**
 * @param {!proto.model.Adm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.BoolValue>}
 *     Promise that resolves to the response
 */
proto.service.usermanagement.CrmPromiseClient.prototype.removeStructureADM =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.usermanagement.Crm/RemoveStructureADM',
      request,
      metadata || {},
      methodDescriptor_Crm_RemoveStructureADM);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.Adm,
 *   !proto.google.protobuf.BoolValue>}
 */
const methodDescriptor_Crm_DeleteStructure = new grpc.web.MethodDescriptor(
  '/service.usermanagement.Crm/DeleteStructure',
  grpc.web.MethodType.UNARY,
  model_crm_pb.Adm,
  google_protobuf_wrappers_pb.BoolValue,
  /**
   * @param {!proto.model.Adm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.BoolValue.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.model.Adm,
 *   !proto.google.protobuf.BoolValue>}
 */
const methodInfo_Crm_DeleteStructure = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_wrappers_pb.BoolValue,
  /**
   * @param {!proto.model.Adm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.BoolValue.deserializeBinary
);


/**
 * @param {!proto.model.Adm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.BoolValue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.BoolValue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmClient.prototype.deleteStructure =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.usermanagement.Crm/DeleteStructure',
      request,
      metadata || {},
      methodDescriptor_Crm_DeleteStructure,
      callback);
};


/**
 * @param {!proto.model.Adm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.BoolValue>}
 *     Promise that resolves to the response
 */
proto.service.usermanagement.CrmPromiseClient.prototype.deleteStructure =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.usermanagement.Crm/DeleteStructure',
      request,
      metadata || {},
      methodDescriptor_Crm_DeleteStructure);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.StructureAssignment,
 *   !proto.google.protobuf.BoolValue>}
 */
const methodDescriptor_Crm_AssignAdm = new grpc.web.MethodDescriptor(
  '/service.usermanagement.Crm/AssignAdm',
  grpc.web.MethodType.UNARY,
  model_crm_pb.StructureAssignment,
  google_protobuf_wrappers_pb.BoolValue,
  /**
   * @param {!proto.model.StructureAssignment} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.BoolValue.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.model.StructureAssignment,
 *   !proto.google.protobuf.BoolValue>}
 */
const methodInfo_Crm_AssignAdm = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_wrappers_pb.BoolValue,
  /**
   * @param {!proto.model.StructureAssignment} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.BoolValue.deserializeBinary
);


/**
 * @param {!proto.model.StructureAssignment} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.BoolValue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.BoolValue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmClient.prototype.assignAdm =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.usermanagement.Crm/AssignAdm',
      request,
      metadata || {},
      methodDescriptor_Crm_AssignAdm,
      callback);
};


/**
 * @param {!proto.model.StructureAssignment} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.BoolValue>}
 *     Promise that resolves to the response
 */
proto.service.usermanagement.CrmPromiseClient.prototype.assignAdm =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.usermanagement.Crm/AssignAdm',
      request,
      metadata || {},
      methodDescriptor_Crm_AssignAdm);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.model.SalesDistrictResponsibilities>}
 */
const methodDescriptor_Crm_GetSalesDistrictResponsibilities = new grpc.web.MethodDescriptor(
  '/service.usermanagement.Crm/GetSalesDistrictResponsibilities',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  model_crm_pb.SalesDistrictResponsibilities,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_crm_pb.SalesDistrictResponsibilities.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.model.SalesDistrictResponsibilities>}
 */
const methodInfo_Crm_GetSalesDistrictResponsibilities = new grpc.web.AbstractClientBase.MethodInfo(
  model_crm_pb.SalesDistrictResponsibilities,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_crm_pb.SalesDistrictResponsibilities.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.model.SalesDistrictResponsibilities)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.SalesDistrictResponsibilities>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmClient.prototype.getSalesDistrictResponsibilities =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.usermanagement.Crm/GetSalesDistrictResponsibilities',
      request,
      metadata || {},
      methodDescriptor_Crm_GetSalesDistrictResponsibilities,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.SalesDistrictResponsibilities>}
 *     Promise that resolves to the response
 */
proto.service.usermanagement.CrmPromiseClient.prototype.getSalesDistrictResponsibilities =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.usermanagement.Crm/GetSalesDistrictResponsibilities',
      request,
      metadata || {},
      methodDescriptor_Crm_GetSalesDistrictResponsibilities);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.Company,
 *   !proto.model.Company>}
 */
const methodDescriptor_Crm_GetOrdersForCompany = new grpc.web.MethodDescriptor(
  '/service.usermanagement.Crm/GetOrdersForCompany',
  grpc.web.MethodType.UNARY,
  model_crm_pb.Company,
  model_crm_pb.Company,
  /**
   * @param {!proto.model.Company} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_crm_pb.Company.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.model.Company,
 *   !proto.model.Company>}
 */
const methodInfo_Crm_GetOrdersForCompany = new grpc.web.AbstractClientBase.MethodInfo(
  model_crm_pb.Company,
  /**
   * @param {!proto.model.Company} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_crm_pb.Company.deserializeBinary
);


/**
 * @param {!proto.model.Company} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.model.Company)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Company>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.usermanagement.CrmClient.prototype.getOrdersForCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.usermanagement.Crm/GetOrdersForCompany',
      request,
      metadata || {},
      methodDescriptor_Crm_GetOrdersForCompany,
      callback);
};


/**
 * @param {!proto.model.Company} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Company>}
 *     Promise that resolves to the response
 */
proto.service.usermanagement.CrmPromiseClient.prototype.getOrdersForCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.usermanagement.Crm/GetOrdersForCompany',
      request,
      metadata || {},
      methodDescriptor_Crm_GetOrdersForCompany);
};


module.exports = proto.service.usermanagement;

