// source: grpc/grpc.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var model_search_pb = require('../model/search_pb.js');
goog.object.extend(proto, model_search_pb);
var model_crm_pb = require('../model/crm_pb.js');
goog.object.extend(proto, model_crm_pb);
var model_listvalueresolver_pb = require('../model/listvalueresolver_pb.js');
goog.object.extend(proto, model_listvalueresolver_pb);
var model_structure_pb = require('../model/structure_pb.js');
goog.object.extend(proto, model_structure_pb);
var model_filtermask_pb = require('../model/filtermask_pb.js');
goog.object.extend(proto, model_filtermask_pb);
