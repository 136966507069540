import * as preact from 'preact'
import rootComponent from './root.component'
import singleSpaPreact from 'single-spa-preact'

// Import the Customizeable less script for SUI
import '../semantic-ui-theme/semantic.less'

const mountPoint = function (elementId, clear) {
    let mount = document.getElementById(elementId)
    if (!mount) {
        mount = document.createElement('div')
        mount.id = elementId
        document.body.appendChild(mount)
        return mount
    }

    if (clear) {
        mount.innerHTML = ''
    }

    return mount
}

const preactLifecycles = singleSpaPreact({
    preact,
    rootComponent,
    domElementGetter: () => mountPoint('content', true),
})

export const { bootstrap, mount, unmount } = preactLifecycles
