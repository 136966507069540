import { h } from 'preact'
import { Button, Header, Modal } from 'semantic-ui-react'
import { Text } from 'preact-i18n'

export default function DelFiltermaskDialog({ open, setOpen, removeFilter }) {
    const handleSubmit = () => {
        removeFilter()
        setOpen(false)
    }

    return (
        <Modal
            mountNode={document.getElementById('content')}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
        >
            <Modal.Header>
                <Text id="delFiltermaskDialog.header">Filtermaske löschen</Text>
            </Modal.Header>
            <Modal.Content>
                <Text id="delFiltermaskDialog.content">Soll die Filtermaske wirklich gelöscht werden?</Text>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setOpen(false)}>
                    <Text id="delFiltermaskDialog.cancel">Abbrechen</Text>
                </Button>
                <Button
                    content={<Text id="delFiltermaskDialog.ok">Löschen</Text>}
                    labelPosition="right"
                    icon="checkmark"
                    onClick={handleSubmit}
                    negative
                />
            </Modal.Actions>
        </Modal>
    )
}
