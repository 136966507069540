import { Component, h, Fragment } from 'preact'
import { Container, Grid, Header, HeaderContent, Form, Segment, Dropdown } from 'semantic-ui-react'

import SemanticDatepicker from 'react-semantic-ui-datepickers'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'

import { Text, Localizer, translate } from 'preact-i18n'
import translation from '../assets/i18n/de_DE.json'

const wrapperFields = {
    lastvisit: {
        type: 'proto.Timestamp',
    },
}

import { store, fetchData } from '../redux/store.js'

const _getSearchConditionOption = (obj) => {
    const options = []

    for (let key in obj) {
        options[options.length] = {
            key: obj[key],
            value: obj[key],
            text: translate('condition.' + key, '', translation),
        }
    }

    return options
}

const SearchConditionOptions = (type) => {
    switch (type) {
        case 'string':
            return _getSearchConditionOption(proto.Search.StringSearchCondition)
        case '[]string':
            return _getSearchConditionOption(proto.Search.StringListCondition)
        case 'number':
            return _getSearchConditionOption(proto.Search.NumberSearchCondition)
        case 'proto.Timestamp':
            return _getSearchConditionOption(proto.Search.TimestampSearchCondition)
    }
}

export default class FilterGroup extends Component {
    constructor(props) {
        super(props)
        this.props = {
            ...props,
        }

        this.state = {
            filterOptions: this.filterOptions(),
            selectedFilter: [],
            resolvingList: true,
            resolvedList: {},
            disabledConditions: {},
            searchValues: {},
            searchConditions: {},
        }

        store.subscribe(() => {
            if (store.getState().listresolver.resolvedList[this.props.type] !== undefined) {
                this.setState({
                    resolvingList: store.getState().listresolver.loadingList,
                    resolvedList: store.getState().listresolver.resolvedList[this.props.type],
                })
            }
        })
    }

    renderSelectionLabel = (label) => ({
        icon: 'user',
        content: `${label.obj.counter} `,
        detail: `${label.obj.name}`,
    })

    findSearchAttribute = (attributename) => {
        let obj = false
        obj = this.props.containerObj.getSearchList().find((obj) => {
            return obj.getLookup() == attributename && obj.getEntitytype() === this.props.type
        })

        return obj
    }

    formFields = () => {
        let fields = {
            Inputs: [],
            Checkboxes: [],
            Selections: [],
        }

        this.state.selectedFilter !== undefined &&
            this.state.selectedFilter.forEach((key, index) => {
                let inputLabel = <Text id={'attributes.' + this.props.type + '.' + key} />

                let prevSearchValue = this.state.searchValues[key] != undefined ? this.state.searchValues[key] : null

                // Defaults
                let attribs = {
                    onChange: (ev, data) => this.setSearchData(ev, data),
                    name: key,
                    value: prevSearchValue,
                    placeholder: inputLabel,
                    autoComplete: 'off',
                }

                let Component = Form.Input
                let field = fields.Inputs

                let conditionOptions = SearchConditionOptions('string')

                switch (typeof this.props.attributes[key]) {
                    case 'boolean':
                        field = fields.Checkboxes
                        conditionOptions = null
                        Component = Form.Checkbox
                        attribs.toggle = true
                        break
                    case 'number':
                        attribs.type = 'number'
                        conditionOptions = SearchConditionOptions('number')
                        break
                    case 'object':
                        field = fields.Selections
                        Component = Form.Dropdown
                        // initialize values if not already done
                        if (
                            this.state.resolvedList[key] === undefined &&
                            store.getState().listresolver.loadingList === false
                        ) {
                            const resolver = new proto.ListValueResolver()
                            resolver.setEntitytype(this.props.type)
                            resolver.setResolvekey(key)
                            store.dispatch(fetchData({ type: 'listresolver', resolve: resolver }))
                        }
                        attribs.value = prevSearchValue === null ? [] : prevSearchValue
                        attribs.clearable = true
                        attribs.multiple = true
                        attribs.upward = false
                        attribs.search = true
                        attribs.selection = true
                        attribs.options = this.state.resolvedList[key]
                        attribs.loading = this.state.resolvingList
                        attribs.renderLabel = this.renderSelectionLabel
                        conditionOptions = SearchConditionOptions('[]string')
                        break
                    case 'undefined':
                        const wrappedType = wrapperFields[key]
                        if (wrappedType !== undefined) {
                            switch (wrappedType.type) {
                                case 'proto.Timestamp':
                                    conditionOptions = SearchConditionOptions('proto.Timestamp')
                                    attribs.type = 'proto.Timestamp'
                                    attribs.locale = 'de-DE'
                                    attribs.format = 'DD.MM.YYYY'
                                    attribs.allowOnlyNumbers = true
                                    attribs.firstDayOfWeek = 1
                                    attribs.minDate = new Date(1879, 0)
                                    attribs.date = new Date()
                                    attribs.autoComplete = 'off'
                                    Component = SemanticDatepicker
                            }
                        }
                }

                field[field.length] = (
                    <Grid.Column verticalAlign="bottom">
                        <Localizer>
                            <span>
                                {inputLabel}
                                {conditionOptions && (
                                    <>
                                        <br />{' '}
                                        <Dropdown
                                            inline
                                            disabled={
                                                (this.state.disabledConditions[key] === undefined ||
                                                    this.state.disabledConditions[key] === true) &&
                                                this.state.searchValues[key] === undefined
                                            }
                                            name={key}
                                            value={
                                                this.state.searchConditions[key] !== undefined
                                                    ? this.state.searchConditions[key]
                                                    : 0
                                            }
                                            options={conditionOptions}
                                            onChange={this.setSearchCondition}
                                        />
                                    </>
                                )}
                            </span>
                            <Component {...attribs} />
                        </Localizer>
                    </Grid.Column>
                )
            })

        fields.Checkboxes = this.chunkArray(fields.Checkboxes, this.props.fieldsperline)
        fields.Inputs = this.chunkArray(fields.Inputs, this.props.fieldsperline)
        fields.Selections = this.chunkArray(fields.Selections, 1)

        return fields
    }

    setSearchValueType(toSearch, forData) {
        let search = null
        let searchValue = forData.value

        switch (forData.type) {
            case 'checkbox':
                toSearch.setBoolValue(forData.checked)
                searchValue = forData.checked
                break
            case 'number':
                search = new proto.Search.IntegerSearch()
                search.setValue(Number(forData.value))
                toSearch.setIntValue(search)
                break
            case undefined:
                if (Array.isArray(forData.value)) {
                    const list = new proto.Search.StringListSearch()
                    forData.value.forEach((val) => list.addValue(val))
                    toSearch.setStrListValue(list)
                }
                break
            case 'proto.Timestamp':
                search = new proto.Search.TimestampSearch()
                search.setValue(proto.Timestamp.fromDate(forData.value))
                toSearch.setTimestampValue(search)
                break
            default:
                search = new proto.Search.StringSearch()
                search.setValue(forData.value)
                toSearch.setStrValue(search)
        }

        this.setState((prevState, props) => ({
            searchValues: {
                ...prevState.searchValues,
                [forData.name]: searchValue,
            },
            disabledConditions: {
                ...prevState.disabledConditions,
                [forData.name]: forData.value === '',
            },
        }))
    }

    setSearchCondition = (ev, condition) => {
        let search = this.findSearchAttribute(condition.name)
        if (!search) {
            return
        }

        // Restore Condition after route change
        this.setState((prev) => (prev.searchConditions[condition.name] = condition.value))

        Object.getOwnPropertyNames(search.__proto__).forEach((p) => {
            const base = p.substring(3)
            if (p.substring(0, 3) === 'has') {
                if (search[p]()) {
                    let c = search['get' + base]()
                    c.setCondition(condition.value)
                }
            }
        })
    }

    // Find previous entered Values
    // return new if not found
    setSearchData = (ev, data) => {
        let search = this.findSearchAttribute(data.name)

        if (search === undefined || search === null) {
            const search = new proto.Search.SearchDetail()
            search.setEntitytype(this.props.type)
            search.setLookup(data.name)

            this.setSearchValueType(search, data)

            this.props.containerObj.addSearch(search)
        } else {
            // Considered Update so should be a new query
            this.setSearchValueType(search, data)
        }
    }

    filterOptions = () => {
        const keys = Object.keys(this.props.attributes)
        let fields = []

        keys.forEach((key) => {
            let inputLabel = translate('attributes.' + this.props.type + '.' + key, '', translation)

            fields[fields.length] = {
                key: key,
                text: inputLabel,
                value: key,
            }
        })

        return fields
    }

    renderSelectedOptions = (data) => {
        if (data.value === undefined) {
            return
        }

        let existingFilter = this.props.containerObj.getSearchList().map((e, l) => {
            return e.getLookup()
        })

        this.setState({
            selectedFilter: data.value,
        })

        const updateSearch = []
        const newSelectedFilter = Array.from(data.value)

        const keepSearch = existingFilter.filter((key) => data.value.includes(key))

        this.props.containerObj.getSearchList().map((ele, idx) => {
            if (ele.getEntitytype() != this.props.type) {
                updateSearch[updateSearch.length] = ele
                return true
            }

            if (keepSearch.indexOf(ele.getLookup()) != -1) {
                newSelectedFilter.splice(newSelectedFilter.indexOf(ele.getLookup()), 1)
                updateSearch[updateSearch.length] = ele
                return true
            }
        })

        newSelectedFilter.forEach((filter) => {
            const searchDetail = new proto.Search.SearchDetail()
            const value = new proto.Search.StringSearch()
            searchDetail.setEntitytype(this.props.type)
            searchDetail.setLookup(filter)
            value.setValue('')
            searchDetail.setStrValue(value)
            updateSearch.push(searchDetail)
        })

        this.props.containerObj.setSearchList(updateSearch)
    }

    chunkArray = (arr, size) => {
        return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size))
    }

    componentDidMount() {
        // Clear previous Search
        // Rerender previous filter from search request
        this.props.containerObj.getSearchList().map((search, idx) => {
            if (this.props.type === search.getEntitytype()) {
                let valueCase = search.getValueCase()
                let value = search.toArray()[valueCase - 1][0]
                let condition = search.toArray()[valueCase - 1][1]

                // Extra parsing
                switch (valueCase) {
                    // Timestamp
                    case 7:
                        value = new Date(value[0] * 1000)
                }

                this.setState((prev) => ({
                    selectedFilter: [...prev.selectedFilter, search.getLookup()],
                    searchValues: { ...prev.searchValues, [search.getLookup()]: value },
                    searchConditions: { ...prev.searchConditions, [search.getLookup()]: condition },
                }))
            }
        })
    }

    componentDidUpdate(previousProps, previousState, snapshot) {
        // TODO better cleanup for wrapped element
        document.getElementsByName('lastvisit').forEach((ele, idx) => {
            ele.parentElement.parentElement.parentElement.style = 'position: relative'
        })

        if (this.props.containerObj.getSearchList() !== previousProps.containerObj.getSearchList()) {
            this.setState({ selectedFilter: [] })
            this.props.containerObj.getSearchList().map((search, idx) => {
                if (this.props.type === search.getEntitytype()) {
                    this.setState((prev) => ({
                        selectedFilter: [...prev.selectedFilter, search.getLookup()],
                    }))
                }
            })
        }
    }

    render(props, state) {
        const fields = this.formFields()

        return (
            <Container>
                <Header>
                    <HeaderContent>
                        <Text id="searchcriteria" />
                        &nbsp;&ndash;&nbsp;
                        <Text id={props.type} plural={1}>
                            {props.type}
                        </Text>
                    </HeaderContent>
                </Header>
                <Form.Dropdown
                    fluid
                    clearable
                    multiple
                    search
                    selection
                    value={state.selectedFilter}
                    options={this.filterOptions()}
                    onChange={(ev, data) => this.renderSelectedOptions(data)}
                />
                <Segment padded>
                    <Header as="h4">
                        <Text id="selections" />
                    </Header>
                    {fields.Selections.map((chunk, index) => (
                        <Form.Group fluid widths="equal">
                            {chunk.map((fields, i) => {
                                return fields
                            })}
                        </Form.Group>
                    ))}
                </Segment>
                <Segment padded>
                    <Header as="h4">
                        <Text id="selections" />
                    </Header>
                    {fields.Checkboxes.map((chunk, index) => (
                        <Grid columns="equal" relaxed="very">
                            {chunk.map((fields, i) => {
                                return fields
                            })}
                        </Grid>
                    ))}
                </Segment>
                <Segment padded>
                    <Header as="h4">
                        <Text id="inputs" />
                    </Header>
                    {fields.Inputs.map((chunk, index) => (
                        <Grid columns="equal" padded>
                            {chunk.map((fields, i) => {
                                return fields
                            })}
                        </Grid>
                    ))}
                </Segment>
            </Container>
        )
    }
}
